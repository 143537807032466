@import "scss/main";

.loader-container {
  display: flex;
  justify-content: center;

  &.loader-container--centered {
    height: 100vh;
    width: 100%;
    align-items: center;
  }

  &.loader-container--fullscreen {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: color("primary-light");
    opacity: 0.7;
    z-index: 1010;
    align-items: center;
  }

  &.loader--inline {
    justify-content: flex-start;
  }
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.loader.loader--sm,
.loader--sm:after {
  width: 20px;
  height: 20px;
}

.loader.loader--md,
.loader--md:after {
  width: 40px;
  height: 40px;
}

.loader.loader--lg,
.loader--lg:after {
  width: 60px;
  height: 60px;
}

.loader {
  font-size: 2px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid color("cta");
  border-right: 1.1em solid transparent;
  border-bottom: 1.1em solid transparent;
  border-left: 1.1em solid transparent;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
