@use "scss/main" as *;
@use "modules/layouts/TriviaLayout/TriviaLayout" as *;

.flow-container:has(.dynamic-form--trivia .trivia__container) {
  position: initial;
}

// position parent element of .dynamic-form--trivia above .header
.App-content__container:has(.dynamic-form--trivia .trivia__container) {
  z-index: $trivia-container-z-index;
}

// override child layout gutters
.dynamic-form__container {
  .coverage-denied__container.container {
    padding-top: 0;
  }
}
