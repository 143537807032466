@import "scss/main";

.add-wysh-modal__container {
  &.modal {
    .modal__container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .modal__header {
      margin-bottom: 16px;

      @include breakpoint(medium) {
        margin-bottom: 25px;
      }
    }

    .modal__btns {
      margin: 0;
    }

    .text-tag {
      text-align: left;
    }
  }

  .add-wysh-modal__body {
    margin-bottom: 24px;
    color: color("smoke");

    @include breakpoint(medium) {
      margin-bottom: 48px;
    }
  }

  .add-wysh-modal__suggestion-options-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 14px;

    @include breakpoint(medium) {
      margin-bottom: 38px;
    }

    .add-wysh-modal__suggestion {
      margin-bottom: 16px;
    }

    .add-wysh-modal__suggestion-option {
      width: 100%;
      margin-bottom: 10px;
      color: color("primary-dark");
      border: 1.5px solid color("primary-dark");
      transition: background-color 0.25s;

      path {
        fill: color("primary-dark");
      }

      &:hover {
        .text-tag {
          color: initial;
        }
      }

      &.active {
        background-color: color("tertiary-bg");
      }
    }
  }

  .add-wysh-modal__number-input {
    margin-bottom: 24px;

    @include breakpoint(medium) {
      margin-bottom: 48px;
    }
  }

  .add-wysh-modal__text--guidance {
    margin-bottom: 24px;
    color: color("smoke");

    @include breakpoint(medium) {
      margin-bottom: 48px;
    }
  }

  .add-wysh-modal__btn--submit {
    width: 100%;
  }
}
