@import "scss/main";

.list-item {
  list-style: none;
  line-height: normal;
  padding: 0;
  min-width: 260px;
  width: 100%;
  height: 61px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  @include breakpoint(medium) {
    max-width: 350px;
    height: 71px;
  }

  @include breakpoint(large) {
    max-width: 425px;
  }

  @include breakpoint(xlarge) {
    max-width: 452px;
  }

  &:hover,
  &.list-item--active {
    background: color("tertiary-bg");
  }

  &.list-item--disabled {
    &:hover {
      background: transparent;
      cursor: auto;
    }
  }

  &.list-item--height-auto {
    min-height: 61px;
    @include breakpoint(medium) {
      min-height: 71px;
    }
    height: auto;
    padding: 12px 0;
  }

  &.list-item--border-bottom {
    border-bottom: 1px solid color("tertiary-bg");
  }

  .text-tag {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .list-item__content {
    height: 100%;
  }

  .list-item__text--header {
    text-transform: uppercase;

    .list-item__right-icon {
      svg {
        stroke: $cta;
      }
    }
  }

  .list-item__text--secondary {
    color: color("secondary-text");
  }

  .label-list-item {
    .list-item__text {
      color: color("secondary-text");
    }

    .list-item__text--secondary {
      color: color("primary-dark");
    }
  }

  .list-item__left-container {
    display: flex;
    align-items: center;
    max-width: 60%;

    &.single-line-list {
      max-width: 80%;
    }
  }

  &.max-width {
    .single-line-list {
        max-width: 100%;
      }
    }


  .list-item__left-container_text {
    max-width: 100%;
  }

  .list-item__right-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .list-item__right-container_text {
    display: flex;
    flex-direction: column;
    margin-left: 5px;

    .text-tag {
      text-align: right;
    }
  }

  .icon-container,
  .icon-wrapper {
    width: 20px;
    max-height: 20px;
  }

  .list-item__left-icon {
    margin-right: 8px;

    @include breakpoint(medium) {
      margin-right: 15px;
    }
  }

  .list-item__right-icon {
    cursor: pointer;
    margin-left: 15px;
  }

  .list-link {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &:hover {
      path {
        fill: color("primary-dark");
      }
    }
  }

  .list-item__right-icon--link {
    path {
      fill: color("cta");
    }
  }

  .list-item__image {
    height: 35px;
    width: 35px;
    min-width: 35px;
    border-radius: 50%;
    margin-right: 15px;
    background: $white;
    font-size: 10px;

    @include breakpoint(medium) {
      height: 40px;
      width: 40px;
      min-width: 40px;
    }

    @include breakpoint(large) {
      height: 48px;
      width: 48px;
      min-width: 48px;
    }
  }
}

.list-item__link {
  .list-item {
    .text-tag {
      color: color("primary-dark");
      transition: $transition-base;
    }

    .icon-container {
      path {
        transition: $transition-base;
      }
    }

    &:hover {
      cursor: pointer;

      .text-tag.list-item__text {
        color: color("cta-pressed");
      }

      .text-tag.list-item__text--secondary {
        color: color("cta-pressed");
      }

      .icon-container {
        path {
          fill: color("cta-pressed");
        }
      }
    }
  }
}
