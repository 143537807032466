@import "scss/main";

$tabs-container-height: 50px;
$jumptarget-offset-sm: $header-height-sm + $tabs-container-height + 20px;
$jumptarget-offset-lg: $header-height-lg + $tabs-container-height + 20px;

.scrolling-tabs__container {
  .scrolling-tabs__header-container {
    padding: 10px 25px 15px 25px;
    margin: 0 auto;

    @include breakpoint(medium) {
      max-width: 450px;
      padding: 10px 25px 35px 25px;
    }
  }

  .scrolling-tabs__header {
    margin-bottom: 15px;
  }

  .scrolling-tabs__tabs-container {
    height: $tabs-container-height;
    margin-bottom: 60px;
    position: sticky;
    top: $header-height-sm;
    background: $primary-light;
    z-index: 10;

    @include breakpoint(medium) {
      top: $header-height-lg;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      height: 3px;
      background: $satin;
      left: 0;
      right: 0;
    }
  }

  .scrolling-tabs__tabs {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;

    @include breakpoint(medium) {
      max-width: 450px;
      margin: 0 auto;
    }

    .scroll-bar {
      height: 3px;
      position: absolute;
      bottom: 0;
      width: 25%;
      background: $primary-dark;
      left: 0;
      right: 0;
      z-index: 10;
      transition: left 0.25s ease-in-out;
    }
  }

  .scrolling-tabs__tab {
    display: flex;
    justify-content: center;
    flex: 1;
    padding-bottom: 5px;
    color: inherit;
    position: relative;
    cursor: pointer;
  }

  .scrolling-tabs__content-container {
    padding: 0 24px;

    @include breakpoint(medium) {
      max-width: 450px;
      margin: 0 auto;
    }
  }

  .scrolling-tabs__content {
    // prevent sections from going behind fixed header when linked to
    margin-bottom: 40px;
    &::before {
      content: "";
      display: block;
      height: $jumptarget-offset-sm;
      margin: -($jumptarget-offset-sm) 0 0;

      @include breakpoint(medium) {
        height: $jumptarget-offset-lg;
        margin: -($jumptarget-offset-lg) 0 0;
      }
    }
  }

  .scrolling-tabs__content-header {
    margin-bottom: 25px;
  }
}
