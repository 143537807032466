@import "scss/main";

.ad-features-card__features {
  display: flex;
  flex-direction: column;

  @include breakpoint(medium) {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }
}

.ad-features-card__feature {
  width: 100%;
  margin: 8px 0;

  @include breakpoint(medium) {
    max-width: 48%;
  }

  @include breakpoint(large) {
    max-width: 200px;
  }
}

.ad-features-card__icon,
.ad-features-card__title {
  margin-bottom: 15px;
}

.ad-features-card__info {
  color: color("secondary-text");
}
