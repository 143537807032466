@use "scss/main" as *;

.home-disclaimers {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: auto;
  padding: 32px 24px;
  text-align: center;
  width: 100%;
  max-width: 1200px;
  color: color("text-secondary");

  @include breakpoint(large) {
    padding: 64px;
  }
}
