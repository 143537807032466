@import "scss/main";

.leading-navigation-button .btn-text {
  text-transform: capitalize;
}

.dynamic-form-layout__modal .leading-navigation-button--modal {
  margin: initial;
  margin-left: auto;
  padding: initial;
  width: initial;

  &:hover,
  &:focus {
    color: initial;

    path {
      fill: initial;
      stroke: initial;
    }
  }

  .icon-container {
    margin: initial;
    width: 24px;
    height: 24px;

    svg {
      width: initial;
    }
  }
}

.leading-navigation-button--portal {
  &.btn .icon-container.left-icon {
    margin-right: 8px;
  }

  .icon-container .svg-wrapper svg {
    width: 24px;
  }

  .icon-container .svg-wrapper svg,
  .icon-container .svg-wrapper svg path {
    fill: color("primary-dark");
    stroke: color("primary-dark");
  }

  .btn-text {
    color: color("primary-dark");
    display: none;

    @include breakpoint(medium) {
      display: block;
      margin-top: 1px; /* Evens things up with the icon */
    }
  }

  &.leading-navigation-button--portal:hover {
    .icon-container .svg-wrapper svg,
    .icon-container .svg-wrapper svg path {
      fill: color("secondary-text");
      stroke: color("secondary-text");
    }

    .btn-text {
      color: color("secondary-text");
    }
  }
}
