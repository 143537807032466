@import "scss/main";

$menu-padding: 46px;

.sidebar-layout {
  display: flex;
  min-height: 100vh;
  padding-top: $header-height-sm;

  @include breakpoint(medium) {
    padding-top: $header-height-lg;
  }

  .sidebar-layout__menu-container {
    background: color("secondary-dark");
    width: 300px;
    padding-top: 45px;
    display: none;

    @include breakpoint(medium) {
      display: block;
    }

    @include breakpoint(large) {
      width: 315px;
    }

    @include breakpoint(xlarge) {
      width: 415px;
      padding-top: 50px;
      padding-left: 75px;
    }

    .sidebar-layout__menu {
      position: relative;

      .menu-wrapper {
        width: 100%;
        background: color("secondary-dark");
        border: 0;
      }
      .menu-item__border--bottom {
        &::after {
          margin-right: 23px;
          margin-left: 23px;
          width: calc(100% - #{$menu-padding});
        }
      }
    }
  }

  .sidebar-layout__content {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 100px 25px 0;

    @include breakpoint(medium) {
      max-width: 400px;
      padding-top: 200 - $header-height-lg;
    }

    @include breakpoint(large) {
      padding: (200 - $header-height-lg) 0 0;
    }

    .list {
      .list-item {
        padding-right: 25px;
        max-width: 100%;

        @include breakpoint(medium) {
          padding-right: 0;
        }
      }
    }
  }
}
