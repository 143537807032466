@import "scss/main";

.about-ad {
  .review-offerings__header {
    background-color: initial;
  }

  .review-offerings__subtitle {
    margin-bottom: 25px;

    @include breakpoint(medium) {
      margin-bottom: 35px;
    }
  }

  .ad-features-card.offer-card__container {
    margin-right: 0;

    @include breakpoint(large) {
      max-width: 950px;
    }
  }

  &.review-offerings__two-offerings {
    .review-offerings__offerings {
      @include breakpoint(medium down) {
        flex-direction: column;
        align-items: center;
      }
    }

    .ad-features-card.offer-card__container {
      @include breakpoint(large) {
        max-width: 400px;
        margin-right: 45px;
      }
    }

    .ad-features-card__feature {
      @include breakpoint(medium) {
        max-width: 46%;
      }
    }
  }
}

.about-ad__cta--apply {
  width: 100%;
  max-width: 350px;
  margin-bottom: 35px;
}

.ad-features-card {
  @include breakpoint(medium down) {
    order: 2;
    margin-top: 20px;
  }
}
