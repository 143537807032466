@import "scss/main";

.flow-review-field {
  background-color: color("secondary-light");
  padding: 12px;
}

.flow-review-field__header {
  position: relative;
}

.flow-review-field__label {
  margin-bottom: 4px;
  margin-right: 36px;
}

.flow-review-field__edit-btn {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;

  path {
    fill: color("ashes");
  }

  &:hover,
  &:focus {
    path {
      fill: color("primary-dark");
    }
  }

  &.flow-review-field__edit-btn--disabled {
    pointer-events: none;
  }
}
