@import "scss/main";

.not-found__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px 36px;
}

.not-found__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: $max-column-width;
}

.not-found__header {
  margin: 12px 0;

  @include breakpoint(large) {
    margin: 24px 0;
  }
}

.not-found__subheader {
  margin-bottom: 36px;
}

.not-found__cta {
  margin-bottom: 8px;
  width: 100%;
}
