@import "scss/main";

.customer-support-content__wrapper {
  display: flex;
  flex-direction: column;
  max-width: 485px;
  width: 100%;

  .customer-support-content__title {
    margin-top: 24px;
    margin-bottom: 24px;

    @include breakpoint(medium) {
      text-align: center;
    }
  }

  .customer-support-content__body {
    margin-top: 24px;
    margin-bottom: 15px;
    color: color("secondary-text");

    @include breakpoint(medium) {
      text-align: center;
    }
  }

  .customer-support-content__icon {
    @include breakpoint(medium) {
      max-height: 48px;
      width: 48px;
    }
  }

  .customer-support-content__cta {
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    width: fit-content;
    @include breakpoint(medium) {
      flex-direction: column;
      margin: auto;
    }
  }

  .customer-support-content__text {
    color: color("cta");
    letter-spacing: 1px;
    margin-left: 16px;
    @include breakpoint(medium) {
      margin-left: 0px;
      margin-top: 16px;
    }
  }
}
