@import "scss/main";

.purchase-policy__cc-entry-container,
.purchase-policy__legalese {
  max-width: 100%;
  width: 100%;

  @include breakpoint(large) {
    max-width: 400px;
  }
}

.purchase-policy__info,
.purchase-policy__legalese--header,
.purchase-policy__legalese--bullets,
.ad-disclosure__checkbox {
  color: color("secondary-text");
}

.purchase-policy__info {
  margin-bottom: 2rem;
}

.purchase-policy__coverages {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  width: 100%;

  @include breakpoint(medium) {
    flex-direction: row;
    gap: 70px;
    max-width: 100%;
  }
}

.purchase-policy__legalese--header,
.payment-card-input__container {
  margin-bottom: 0.5rem;
}

.purchase-policy__cc-icons {
  margin: 1.5rem 0;
}

.purchase-policy__legalese--bullets {
  ol {
    margin-bottom: 0;

    li {
      margin-bottom: 0.5rem;
    }
  }
}

.purchase-policy__legalese-item {
  .purchase-policy__sogh-link,
  .purchase-policy__policy-link {
    display: block;
    margin-top: 0.5rem;
  }
}

.purchase-policy__sogh-link,
.purchase-policy__policy-link {
  .text-tag {
    display: inline;
  }
}

.purchase-policy__cta {
  margin: 1.5rem 0;
  width: 100%;

  @include breakpoint(medium) {
    max-width: 400px;
  }
}

.purchase-policy__container {
  &.message-layout__container .message-layout__wrapper {
    max-width: 540px;

    @include breakpoint(large) {
      max-width: 850px;
    }
  }

  .purchase-policy__inputs {
    display: flex;
    flex-direction: column;

    @include breakpoint(large) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .card--container {
    max-width: 100%;
    width: 100%;

    @include breakpoint(large) {
      max-width: 400px;
    }
  }

  .card-content__multiple-ad-container {
    .card-content__multiple-ad-grid {
      display: flex;
      row-gap: initial;
    }

    .card-content__multiple-ad-carousel {
      display: none;
    }

    .card-content__multiple-ad {
      width: 100%;
      margin-bottom: 2.5rem;

      @include breakpoint(large) {
        width: calc(50% - 12px);
      }

      .card-content__multiple-ad-description {
        color: color("secondary-text");
      }

      &:last-child {
        .card-content__multiple-ad-img {
          width: 150px;

          @media screen and (min-width: 28rem) {
            margin-top: 15px;
            margin-bottom: 25px;
          }
        }

        .card-content__multiple-ad-title {
          letter-spacing: 1px;
        }
      }
    }
  }
}

.purchase-policy__form {
  &.disabled {
    .payment-card-input__container,
    .text-input,
    .checkbox {
      opacity: 0.4;
      transition: opacity 0.25s ease-in;
      pointer-events: none;
    }
  }
}
