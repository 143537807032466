@import "scss/main";

.message-layout__container {
  padding-top: calc(24px + #{$header-height-sm});
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 50px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  @include breakpoint(medium) {
    padding-top: calc(50px + #{$header-height-sm});
    padding-left: 50px;
    padding-right: 50px;
  }

  @include fadeIn();

  &.has-backnav {
    padding-top: 0;
  }

  .message-layout__wrapper {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;

    .message-layout__image-wrapper {
      .message-layout__image {
        width: 100%;
      }
    }

    .message-layout__content-wrapper {
      .message-layout__content-title-wrapper {
        .message-layout__content-title {
          margin-top: 12px;
          margin-bottom: 24px;
        }
      }
    }
  }
}
