@import "scss/main";

.value-props-carousel__container {
  margin-bottom: 20px;
  position: relative;
}

.value-props-carousel__wrapper {
  padding-bottom: 20px;
}

.value-props-carousel__arrows {
  display: none;

  @include breakpoint(medium) {
    display: flex;
    position: absolute;
    left: 0%;
  }

  @include breakpoint(large) {
    left: calc(50% + 30px);
  }
}

.value-props-carousel__dots {
  width: auto;
  bottom: auto;
  left: 0%;

  @include breakpoint(medium) {
    left: auto;
    right: 0%;
  }

  li {
    button {
      &::before {
        background: black;
        width: 24px;
        height: 3px;
        content: "";
      }
    }
  }
}
