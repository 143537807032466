@import "scss/main";

.screen-loader-container {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: color("primary-light");
  opacity: 0.9;
  transition: opacity 0.2s ease-in;
  height: 100%;
  width: 100%;

  .screen-loader-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .screen-loader__image--wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: 100%;
      max-width: 300px;
      max-height: 300px;
    }
  }
}
