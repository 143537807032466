@import "scss/main";

.toggle__container {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;

  .toggle__input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .toggle__slider {
      background-color: color("black");
      &::before {
        transform: translateX(23px);
      }
    }
  }

  .toggle__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: color("tertiary-bg");
    transition: 0.4s;
    border-radius: 15px;

    &::before {
      position: absolute;
      content: "";
      height: 18px;
      width: 18px;
      left: 3px;
      bottom: 3px;
      background-color: white;
      transition: 0.4s;
      border-radius: 50%;
    }
  }
}
