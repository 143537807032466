@import "scss/main";

.custom-link {
  &.custom-link--bold {
    font-weight: 700;
  }
  line-height: 170%;
  text-align: center;
  letter-spacing: 1px;
  padding: 16px;
  display: flex;
  align-items: center;
  text-decoration: none !important;
  background: transparent;

  &.custom-link--inline {
    display: inline-block;
    padding: 0;
  }

  .text-tag {
    transition: color 0.2s;
  }

  .icon-container {
    max-height: 24px;
    max-width: 24px;

    &.left-icon {
      margin-right: 8px;
    }
    &.right-icon {
      margin-left: 8px;
    }
  }

  .right-icon {
    margin-left: 10px;
  }

  path {
    transition: fill 0.2s;
    fill: color("cta");
  }

  &.border {
    border: 1px solid color("cta");
  }

  &:hover {
    .text-tag {
      color: color("cta-pressed");
    }
  }

  &.disabled {
    pointer-events: none;
    color: color("btn-inactive-light");

    path {
      fill: color("btn-inactive-light");
    }
  }

  &.light {
    color: color("primary-light");

    path {
      fill: color("primary-light");
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  &.primary {
    background: color("cta");
    color: color("primary-light");
    transition: background 0.2s;

    &:hover {
      .text-tag {
        color: color("primary-light");
      }

      background: color("cta-pressed");
    }

    path {
      fill: color("primary-light");
    }

    &.disabled {
      background: color("btn-inactive-light");
    }
  }

  &.secondary {
    background: color("primary-dark");
    color: color("primary-light");

    path {
      fill: color("primary-light");
    }

    &.disabled {
      background: color("btn-inactive-dark");
    }
  }
}
