@import "scss/main";

.header-navigation {
  display: flex;
}

.header-navigation__link {
  color: color("primary-dark");
  display: none;

  @include breakpoint(960px) {
    display: flex;

    &:hover {
      color: color("cta");
    }
  }
}

.header-navigation__link--active {
  color: color("primary-dark");
  pointer-events: none;

  @include breakpoint(960px) {
    /* extra class to override "html body a:focus" */
    &.header-navigation__link--active {
      color: color("cta");
    }
  }

  &:not(.header-navigation__link--home) {
    display: flex;
  }
}
