@import "scss/main";

.coverage-denied__container {
  @include fadeIn();

  .text-tag.p3-tag__text {
    margin-bottom: 20px;
    color: color("secondary-text");
  }
}
