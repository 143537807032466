@import "scss/main";

.splash-message__container {
  padding-top: calc(24px + #{$header-height-sm});
  padding-left: 24px;
  padding-right: 24px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  min-height: 100%;

  @include breakpoint(medium) {
    padding-top: calc(50px + #{$header-height-sm});
    padding-left: 50px;
    padding-right: 50px;
  }

  .backnav {
    margin-bottom: 0;
  }

  .splash-message__wrapper {
    margin-left: auto;
    margin-right: auto;

    .splash-message__background-image_wrapper {
      position: relative;
      z-index: 10;

      .splash-message__background-image {
        position: absolute;
        top: -80px;
        left: calc(50% - 180px + 4vw);
        max-width: 100%;

        @include breakpoint(xlarge) {
          left: calc(50% - 140px);
        }
      }
    }

    .splash-message__content-wrapper {
      position: relative;
      z-index: 20;

      .splash-message__content-title-wrapper {
        .splash-message__content-title {
          margin-top: 12px;
          margin-bottom: 24px;
        }
      }
    }
  }
}
