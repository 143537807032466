@import "scss/main";

.header-menu {
  display: flex;
}

.header-menu__toggle {
  background-color: inherit;
  border: 0;
  height: 32px;
  padding: 0;
  position: relative;
  width: 32px;
}

.header-menu__toggle-icon {
  @include flipInX();
  cursor: pointer;
  left: 0;
  position: absolute;
  top: 0;

  &:not(.header-menu__toggle-icon--active) {
    @include flipOutY(1, 0.2s);
  }
}

.header-menu__menu {
  background-color: initial;
  left: 0;
  position: fixed;
  right: 0;
  top: $header-height-sm;
  transition: background-color 0.25s ease-out;

  .header-menu--open & {
    bottom: 0;
  }

  @include breakpoint(768px) {
    left: unset;
  }

  /* TODO: Move this to Menu.scss when app-architecture-navigation-web flag is removed */
  .menu-wrapper {
    @include breakpoint(medium) {
      width: 100%;
    }

    @include breakpoint(768px) {
      width: 368px; /* 16px * 23 -- closest to what is in Figma */
    }
  }
}
