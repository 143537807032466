@import "scss/main";

.icon-container {
  width: 32px;
  max-height: 32px;

  &.xs-size {
    width: 16px;
    max-height: 16px;
  }
  &.sm-size {
    width: 24px;
    max-height: 24px;
  }
  &.md-size {
    width: 32px;
    max-height: 32px;
  }
  &.lg-size {
    width: 48px;
    max-height: 48px;
  }
  &.xl-size {
    width: 64px;
    max-height: 64px;
  }

  .icon-wrapper {
    .icon-content {
      .svg-wrapper {
        display: flex;
        svg {
          max-width: 100%;
          height: 100%;
        }
      }
    }
  }
}
