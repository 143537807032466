@use "scss/main" as *;

.form-group--input-phone {
  display: flex;
  align-items: center;
  gap: 8px;

  .form-item--input-single-select {
    max-width: 120px;
    min-width: 100px;
    width: 100%;

    @include breakpoint(medium) {
      min-width: 120px;
    }

    .text-input_input {
      padding: 12px 32px 12px 12px;
      font-size: 12px;

      @include breakpoint(medium) {
        padding: 16px 40px 16px 16px;
        font-size: 14px;
      }
    }

    label {
      visibility: hidden;
    }

    .icon-container {
      width: 20px;
      max-height: 20px;
    }

    .custom-select__item {
      padding: 0 12px;
    }
  }

  .form-item--input-text {
    max-width: 70%;
    min-width: 60%;
    width: 100%;
  }
}
