@import "scss/main";

.journey-progress {
  position: absolute;
  top: $header-height-sm;

  @include breakpoint(medium) {
    top: $header-height-lg;
  }
}

.journey-progress--hidden {
  opacity: 0;
}
