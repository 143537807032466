@import "scss/main";

.card-content__ad-container {
  .card-content__ad-title {
    text-transform: uppercase;
    margin-bottom: 25px;

    @include breakpoint(medium) {
      margin-bottom: 35px;
    }
  }

  .card-content__ad-headline,
  .card-content__ad-description {
    margin-bottom: 25px;
  }
}

.card-content__single-ad-container {
  display: flex;
  align-items: center;

  .card-content__single-ad-link {
    display: block;
    width: 180px;
  }

  .card-content__single-ad-img {
    width: 280px;
    height: 230px;

    @include breakpoint(medium) {
      width: 360px;
      height: 290px;
    }

    @include breakpoint(large) {
      width: 415px;
      height: 340px;
    }
  }
}

.card-content__multiple-ad-container {
  display: block;

  .card-content__multiple-ad {
    width: 50%;
    @include breakpoint(large) {
      width: 33%;
    }
  }
  .card-content__multiple-ad-img {
    width: 25%;

    min-width: 60px;
    margin-left: 0;
    margin-bottom: 15px;
  }
  .card-content__multiple-ad-title {
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  .card-content__multiple-ad-link {
    display: block;
    margin: 0 auto;
    width: 100%;
    margin-top: 45px;

    @include breakpoint(medium) {
      width: 320px;
    }

    @include breakpoint(large) {
      width: 350px;
    }
  }

  .card-content__multiple-ad-grid {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    row-gap: 2.5em;

    @include breakpoint(small down) {
      display: none;
    }
  }

  .card-content__multiple-ad-carousel {
    width: 100%;

    @include breakpoint(medium) {
      display: none;
    }

    .card-content__multiple-ad {
      width: 100%;
      margin-left: 5px;
    }

    .card-content__multiple-ad-carousel-dots {
      text-align: left;
      bottom: -35px;

      li {
        button {
          &::before {
            background: black;
            width: 24px;
            height: 3px;
            content: "";
          }
        }
      }
    }
  }

  &.hide-carousel {
    .card-content__multiple-ad-grid {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;

      @include breakpoint(medium) {
        flex-direction: row;
        flex-wrap: wrap;
      }

      @include breakpoint(large) {
      }

      .card-content__multiple-ad {
        width: 100%;
        margin-bottom: 36px;

        @include breakpoint(medium) {
          max-width: 50%;
        }

        @include breakpoint(large) {
          max-width: 25%;

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }

    .card-content__multiple-ad-carousel {
      display: none;
    }
  }
}
