.corner-blob {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -40;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;

  .corner-blob--md {
    @media screen and (min-width: 518px) and (max-width: 830px) {
      width: 200px;
    }
  }
}
