@import "scss/main";

@keyframes cta-bg-slide {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
}

.trivia-answer__container {
  background-color: color("tertiary-bg");
  background-image: url("../../assets/images/trivia/constellation-black.svg");
  background-position: 49% -198px;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  justify-content: space-between;
  padding: 130px 20px 20px;
}

.trivia-answer__result-icon {
  display: block;
  margin: 0 auto 16px;
}

.trivia-answer__result,
.trivia-answer__correct-answer {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 24px;
  text-align: center;
}

.trivia-answer__result--correct {
  color: color("success-light");
}

.trivia-answer__result--incorrect {
  color: color("error-dark");
}

.trivia-answer__correct-answer-heading {
  font-size: 14px;
  text-align: center;
}

.trivia-answer__explanation {
  color: color("secondary-text");
  font-size: 13px;
  text-align: center;
}

.trivia-answer__cta {
  border-radius: 60px;
  color: color("secondary-light");
  font-weight: $font-weight-bold;
  letter-spacing: -0.5px;
  line-height: 1.6;
  /* Solves Safari misbehaving with overflow + border-radius */
  mask-image: radial-gradient(white, black);
  min-height: calc((16px * 1.6) + 32px);
  position: relative;
  overflow: hidden;
  text-transform: none;
  width: 100%;

  &#{&} {
    background-color: color("cta");
  }
}

.trivia-answer__cta-background {
  animation: linear 1 forwards cta-bg-slide;
  background-color: #bdb5eb;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.trivia-answer__cta-text {
  position: relative;
}
