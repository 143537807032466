@import "scss/main";

.trivia-banner__container {
  align-items: center;
  background-color: color("cta");
  cursor: pointer;
  display: flex;
  height: 80px;
  justify-content: space-between;
  left: 0;
  padding-left: 20px;
  padding-right: 20px;
  position: fixed;
  right: 0;
  top: 0;

  @include breakpoint(medium) {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }
}

.trivia-banner__cta-text {
  color: color("primary-light");
  font-size: 11px;
  letter-spacing: 1px;
  margin-right: 20px;
  text-transform: uppercase;
}

.trivia-banner__icon {
  .icon-content {
    path {
      fill: color("primary-light");
    }
  }
}
