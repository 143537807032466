// Used for mapping display values
$display_map: (
  display: (
    hidden: "none",
    inline: "inline",
    inline-block: "inline-block",
    block: "block",
    initial: "initial",
    flex: "flex",
  ),
  visibility: (
    invisible: "hidden",
    visible: "visible",
  ),
);



@include each-breakpoint() {
  @each $name, $map in $display_map {
    @each $property, $value in $map {
      $size: $-zf-size;
      $appendix: "" !default;

      @if $size != "small" {
        $appendix: $breakpoint-separator + $size;
      }

      .appear-#{$property + $appendix} {
        #{$name}: unquote($value) !important;
      }
    }
  }
}
