@import "scss/main";

.card--dashboard {
  background-color: color("secondary-light");
  border: 0.5px solid color("primary-dark");
  border-radius: 10px;
  box-shadow: 0px 4px 8px 0px rgba(39, 55, 64, 0.1);
  padding: 30px 12px;

  @include breakpoint(medium) {
    margin: 0 auto;
    padding: 30px 24px;
    width: 400px;
  }

  @include breakpoint(large) {
    margin: 0;
  }

  .card__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    @include breakpoint(medium) {
      margin-bottom: 16px;
    }
  }

  .card__icon {
    path {
      fill: color("cta");
    }
  }

  .card__body {
    &:not(:last-child) {
      margin-bottom: 12px;

      @include breakpoint(medium) {
        margin-bottom: 16px;
      }
    }
  }

  .card__cta,
  .card__cta-btn {
    width: 100%;
  }
}
