@import "scss/main";

.wyshes-by-category__list-container {
  .wyshes-by-category__list-wrapper {
    margin-bottom: 64px;

    .wyshes-by-category__list {
      .wyshes-by-category__list-item {
        color: color("primary-dark");

        &:last-of-type {
          .divider {
            display: none;
          }
        }

        .wishes-list-item__content-left {
          max-width: 80%;

          @include breakpoint(large) {
            max-width: 100%;
          }
        }

        .wishes-list-item__text-wrapper {
          max-width: 100%;
        }
      }
    }
  }
}
