@use "scss/main" as *;

.beneficiary-review {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 36px;
}

.beneficiary-review__section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: $max-column-width;
  width: 100%;
  margin: auto;

  @include breakpoint(medium) {
    padding: 0;
  }

  &.beneficiary-review__section-backup {
    max-width: 100%;
    background-color: color("background-secondary");

    @include breakpoint(small only) {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 0px;
        left: -24px;
        width: calc(100% + 48px);
        height: 100%;
        background-color: color("background-secondary");
        z-index: -1;
      }
    }
  }

  .text-link {
    align-self: flex-start;
  }
}

.beneficiary-review__section-backup-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: $max-column-width;
  margin: 36px auto 24px;
  gap: 8px;
}

.beneficiary-review__header {
  gap: 16px;
}

.beneficiary-review__beneficiaries-list {
  .beneficiary-review-contact {
    margin-bottom: 24px;
  }
}

.beneficiary-review__add-beneficiary-btn {
  margin: 16px 0;
}
