@import "scss/main";

.product-offering-container {
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  gap: 60px;
  max-width: 860px;
  padding: 0 24px 48px;

  @include breakpoint(medium) {
    flex-direction: row;
    padding-bottom: 64px;
  }

  .product-offering__column:not(.product-offering__column--sidebar) {
    flex-grow: 1;
  }

  .product-offering__column--sidebar {
    @include breakpoint(medium) {
      width: 240px;
    }
  }

  .product-offering__header {
    margin-bottom: 4px;
  }

  .product-offering__subheader {
    margin-bottom: 32px;
  }

  .product-offering__premium-info {
    margin-bottom: 16px;
  }

  .product-offering__details {
    margin-bottom: 16px;
  }
}
