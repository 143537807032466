.form__input {
  margin-bottom: 8px;
}

.form__submit {
  margin-top: 35px;
}

.form__section {
  margin-bottom: 35px;
  .form__section-title {
    margin-bottom: 15px;
    text-transform: uppercase;
  }
}
