@import "scss/main";

.slider-input__container {
  width: 100%;
  max-width: 350px;
  margin-bottom: 25px;

  @include breakpoint(medium) {
    max-width: 420px;
  }

  .slider-input__label--wrapper {
    width: 100%;
    margin-bottom: 15px;

    .text-tag {
      margin: 0;
    }

    .slider-input__number-text--limit {
      text-align: right;
    }

    .slider-input__number--wrapper {
      border-bottom: 1px solid color("cta");

      .slider-input__number {
        text-align: right;
        padding: 5px 0;
        line-height: normal;
        border: none;
        outline: 0;
        background: color("primary-light");
        caret-color: color("cta");
        min-width: 10px;
        font-family: $ff-body;
        font-weight: 600;
        font-size: 16px;

        @include breakpoint(medium) {
          font-size: 18px;
        }

        @include breakpoint(large) {
          font-size: 21px;
        }
      }

      .slider-input__number-unit--left {
        margin-right: 2px;
      }

      .slider-input__number-unit--right {
        margin-left: 4px;
      }
    }

    &.inactive {
      .slider-input__number--wrapper {
        border-bottom: 1px solid color("primary-dark");
      }
    }
  }
}
