@import "scss/main";

.radio {
  width: 100%;
  max-width: 288px;
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  margin-right: 25px;
  cursor: pointer;

  @include breakpoint(medium) {
    max-width: 350px;
  }

  @include breakpoint(large) {
    max-width: 400px;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &.focus-visible + .radio__label-text {
      outline: color("focus-outline") solid 1px;
    }
  }

  input:checked ~ .radio__checkmark:after {
    display: block;
  }

  &.radio--bordered {
    padding: 20px 20px 20px 55px;

    &.radio--bordered-inactive {
      background: color("100");
    }

    .radio__checkmark {
      left: 20px;
    }
  }
}

.radio--disabled {
  cursor: none;
  opacity: 0.5;
  pointer-events: none;
}

.radio__checkmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: $tertiary-bg;

  .icon-container {
    transition: opacity 0.2s;
    opacity: 0;
  }

  &.checked {
    .icon-container {
      opacity: 1;
    }
  }
}
