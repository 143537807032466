@import "scss/main";

.create-phone-number {
  .create-phone-number__permission {
    margin-top: 50px;
    margin-bottom: 15px;

    @include breakpoint(medium) {
      margin-top: 65px;
      margin-bottom: 35px;
    }
  }
}
