@import "scss/main";

.card-container {
  &.card-container__add-wysh-counter {
    padding: 16px 12px;
    max-width: 132px;
    width: 100%;
    height: 86px;
    color: color("primary-dark");
    background-color: color("secondary-dark");
    transition: background-color $transition-duration;
    border: 0;
    text-align: left;
    cursor: pointer;

    &:hover {
      background: color("tertiary-bg");
    }

    @include breakpoint(medium) {
      max-width: 160px;
      height: 110px;
      padding: 16px;
    }

    @include breakpoint(large) {
      max-width: 200px;
    }

    .card-content__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 100%;
    }

    .text__wrapper {
      overflow: hidden;

      .text-tag {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .icon-counter__wrapper {
      position: relative;
      height: 36px;
      min-width: 36px;

      @include breakpoint(medium) {
        height: 48px;
        min-width: 48px;
      }

      @include breakpoint(large) {
        height: 64px;
        min-width: 64px;
      }
    }

    .add-wysh-counter__icon-wrapper {
      position: absolute;

      .add-wysh-counter__icon {
        height: 100%;
        width: 100%;

        @include fadeIn();
      }
    }

    .add-wysh-counter__total-wrapper {
      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: color("success-light");
      width: 20px;
      height: 20px;
      border-radius: 50%;

      @include breakpoint(medium) {
        width: 24px;
        height: 24px;
      }

      @include breakpoint(large) {
        width: 26px;
        height: 26px;
      }

      .add-wysh-counter__total {
        color: color("primary-light");
      }

      &.hide {
        visibility: hidden;
      }
    }

    .card-content {
      margin: 0;
    }
  }
}
