@import "scss/main";

.modal {
  .payout-details-modal__items {
    p {
      text-align: left;
    }
  }
}

.payout-details-modal__item {
  margin-bottom: 25px;
}

.payout-details-modal__item-amount {
  margin-bottom: 10px;
}

.payout-details-modal__item-label {
  margin-bottom: 15px;
}

.payout-details-modal__item-description,
.payout-details-modal__legalese--text {
  color: color("secondary-text");
}
