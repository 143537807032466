@import "scss/main";

.wishes-list-item {
  line-height: normal;
  padding: 0;
  min-width: 260px;
  max-width: 100%;
  width: 100%;
  height: 60px;

  @include breakpoint(medium) {
    max-width: 350px;
    height: 70px;
  }

  @include breakpoint(large) {
    max-width: 400px;
  }

  @include breakpoint(xlarge) {
    max-width: 450px;
  }

  .wishes-list-item__content {
    height: 100%;

    .wishes-list-item__icon-wrapper {
      height: 40px;
      width: 40px;
      margin-right: 16px;
      overflow: hidden;
      border-radius: 50%;

      @include breakpoint(medium) {
        height: 48px;
        width: 48px;
      }

      .wishes-list-item__icon {
        object-fit: cover;
        object-position: center;
      }
    }

    .wishes-list-item__content-left {
      display: flex;
      align-items: center;
      max-width: 100%;

      @include breakpoint(medium) {
        max-width: 80%;
      }
    }

    .wishes-list-item__text-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1;

      .text-tag {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .wishes-list-item__remove-icon {
      cursor: pointer;
      background-color: inherit;
      border: 0;
    }

    .wishes-list-item__text--secondary {
      color: color("secondary-text");
    }

    .wishes-list-item__icon {
      margin-right: 15px;
    }
  }
}
