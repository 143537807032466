@import "scss/main";

.tl-product-offering-layout {
  // TODO: Update top margin when Exit/Back button moves to header
  margin: 0;

  > * {
    margin: 0 auto;
  }

  .faq {
    display: flex;
    justify-content: Center;
  }

  .faq__wrapper {
    max-width: 860px;
  }
}
