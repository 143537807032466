@import "scss/main";

.navigation-section:not(:empty) {
  max-width: 1200px;
  margin: 0 auto 24px;
  padding: calc($header-height-sm + 24px) 16px 0 16px;

  @include breakpoint(large) {
    padding: calc($header-height-lg + 24px) 0 0;
    margin: 0 auto 48px;
  }

  .icon-container {
    svg {
      stroke: $cta;
    }
  }

  .backnav {
    margin-bottom: 0;
  }
}

.navigation-section.leading-navigation-buttons {
  margin-bottom: 0;
}

.navigation-section--portal {
  justify-content: flex-end;
  padding-top: calc(32px + 80px);

  @include breakpoint(medium) {
    padding-top: calc(48px + 80px);
  }

  @include breakpoint(large) {
    padding-top: calc(96px + 80px);
  }
}

.space-navigation-links {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin: auto;

  .backnav--self-positioned {
    margin: initial;
  }
}
