@import "scss/main";

$jumptarget-offset-sm: $header-height-sm + 100px;
$jumptarget-offset-lg: $header-height-lg + 100px;

.sidebar-tabs__container {
  width: 930px;
  margin: 0 auto;

  .sidebar-tabs__left-col {
    width: 465px;
    position: fixed;
  }

  &.has-backnav {
    padding-top: 35px;
  }

  .sidebar-tabs__right-col {
    float: right;
    width: 465px;
    padding-bottom: 50px;
  }

  .sidebar-tabs__header-container {
    margin-bottom: 50px;
  }

  .sidebar-tabs__header {
    margin-bottom: 15px;
  }

  .sidebar-tabs__tab {
    .list-item,
    .list-item--active {
      background: transparent;
      &:hover {
        background: transparent;
      }
    }

    .list-item__text {
      color: $primary-dark;
      text-align: left;
    }

    .list-item__right-icon {
      opacity: 0;
      svg {
        path {
          fill: color("cta");
        }
      }
    }
  }

  .sidebar-tabs__tab--active {
    .list-item__text {
      color: $cta;
    }
    .list-item__right-icon {
      opacity: 1;
    }
  }

  .sidebar-tabs__tab-description {
    color: $secondary-text;
  }

  .footer-container {
    display: none;
  }

  // prevent sections from going behind fixed header when linked to
  .sidebar-tabs__content {
    margin-bottom: 60px;
    &::before {
      content: "";
      display: block;
      height: $jumptarget-offset-sm;
      margin: -($jumptarget-offset-sm) 0 0;

      @include breakpoint(medium) {
        height: $jumptarget-offset-lg;
        margin: -($jumptarget-offset-lg) 0 0;
      }
    }
  }

  .sidebar-tabs__content-header {
    margin-bottom: 30px;
  }
}

// prevent backnav from scrolling off screen
.backnav.backnav--SidebarTabsLayout {
  position: fixed;
}
