@import "scss/main";

.multiple-choice-input-container {
  padding: 20px;

  .checkbox {
    margin-top: 20px;
    margin-bottom: 20px;

    @include breakpoint(medium) {
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }

  &.flex-column--start {
    .checkbox {
      width: 100%;
      margin-bottom: 0;
      margin-right: 0;
      padding-right: 24px;

      @include breakpoint(large) {
        padding-right: 30px;
      }
    }
  }
}

.multiple-choice-input__divider {
  padding-bottom: 25px;
  border-bottom: 1px solid color("tertiary-bg");

  &:last-of-type {
    border-bottom: none;
  }
}
