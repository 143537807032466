@use "foundations";

@mixin header-typography-ver2($classSuffix: "") {
  @each $header-type, $header-list in foundations.$header-styles-v2 {
    @each $header-tag, $header-def in $header-list {
      .#{$header-tag + "-" + $header-type + $classSuffix} {
        font-family: $headings-font-family;
        font-size: rem-calc(map-get($header-def, "font-size"));

        @if map-has-key($header-def, "font-weight") {
          font-weight: map-get($header-def, "font-weight");
        }
        @if map-has-key($header-def, "font-family") {
          font-family: map-get($header-def, "font-family");
        }
      }
    }
  }
}

@mixin body-typography-ver2($classSuffix: "") {
  @each $body-type, $body-list in foundations.$body-styles-v2 {
    @each $body-tag, $body-def in $body-list {
      .#{$body-tag + "-" + $body-type + $classSuffix} {
        font-family: $ff-body;
        font-size: rem-calc(map-get($body-def, "font-size"));

        @if map-has-key($body-def, "font-weight") {
          font-weight: map-get($body-def, "font-weight");
        }
        @if map-has-key($body-def, "font-family") {
          font-family: map-get($body-def, "font-family");
        }
        @if map-has-key($body-def, "line-height") {
          line-height: map-get($body-def, "line-height");
        }
      }
    }
  }
}

@mixin generateSpacingClasses($classSuffix: "") {
  $sides: (
    top: "t",
    right: "r",
    bottom: "b",
    left: "l",
  );

  @each $key, $space-value in foundations.$spacing-sizes {
    $space-class: $classSuffix + $key;
    // generate m-* classes
    .m-#{$space-class} {
      margin: $space-value;
    }

    // generate p-* classes
    .p-#{$space-class} {
      padding: $space-value;
    }

    .gap-#{$space-class} {
      row-gap: $space-value;
      column-gap: $space-value;
    }

    @each $side, $side-infix in $sides {
      .m#{$side-infix}-#{$space-class} {
        margin-#{$side}: $space-value;
      }

      .p#{$side-infix}-#{$space-class} {
        padding-#{$side}: $space-value;
      }
    }
  }
}
