.beneficiary-review-contact {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 12px 0;
}

.beneficiary-review-contact__header {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.beneficiary-review-contact__info {
  color: color("text-secondary");
}

.beneficiary-review-contact__allocations {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
