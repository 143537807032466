@import "scss/main";

.product-offering-value-props {
  background-color: color("secondary-light");
  border-radius: $border-radius-xl;
  padding: 24px 12px;

  .product-offering-value-props__item:not(:last-child) {
    margin-bottom: 24px;
  }
}

.product-offering-value-props-item {
  .product-offering-value-props-item__icon,
  .product-offering-value-props-item__description:not(:last-child) {
    margin-bottom: 8px;
  }

  .product-offering-value-props-item__header {
    margin-bottom: 4px;
  }
}

.product-offering-value-props-item__header {
  text-transform: uppercase;
}

.product-offering-value-props-item__description {
  color: color("secondary-text");
}

.product-offering-value-props-item__cta {
  color: color("cta");
  cursor: pointer;
}
