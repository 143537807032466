@import "scss/main";

.add-wyshes__card-wrapper {
  width: 100%;
  height: 132px;
  max-width: 132px;
  overflow: visible;
  position: relative;
  background-color: color("secondary-dark");
  padding: 0;
  border: 0;
  border-radius: 20px;

  @include breakpoint(medium) {
    max-width: 160px;
  }

  @include breakpoint(large) {
    height: 142px;
    max-width: 200px;
  }

  .add-wyshes__card-content {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 16px;
    border-radius: 20px;

    &:hover {
      background: color("tertiary-bg");
    }

    .card-content--top {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    .add-wyshes__total-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: color("primary-dark");
      width: 26px;
      height: 26px;
      border-radius: 50%;

      .add-wyshes__total {
        color: color("primary-light");
      }

      &.hide {
        visibility: hidden;
      }
    }

    .card-content__icon-wrapper {
      align-self: flex-end;
      height: 75px;
      width: 75px;
      margin: -22px -11px 0 0;

      .icon-container {
        width: 75px;
        max-width: 75px;
      }
    }

    .card-content {
      margin: 0;
    }

    &.carousel--item {
      width: 220px !important;
      max-width: 220px;
      height: 142px;
      display: flex !important;
      align-items: flex-end;
      flex-direction: row;
      cursor: pointer;

      @include breakpoint(medium) {
        width: 250px !important;
        max-width: 250px;
        height: 152px;
      }

      @include breakpoint(large) {
        width: 300px !important;
        max-width: 300px;
      }

      .card-content__icon-wrapper {
        position: absolute;
        right: 0;
        top: 0;

        @include breakpoint(medium) {
          height: 100px;
          width: 100px;

          .icon-container {
            width: 100px;
            max-width: 100px;
          }
        }
      }

      .card-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-content: flex-end;
      }
    }
    .card-content__body-text {
      text-align: left;
      color: color("primary-dark");
    }
  }
}
