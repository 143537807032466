@import "scss/main";

.product-offering-details {
  .product-offering-details__header {
    margin-bottom: 4px;
  }
  .product-offering-details__item:not(:last-child) {
    border-bottom: 1px solid color("tertiary-border");
  }
}

.product-offering-details-header {
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  padding: 12px 0;
}

.product-offering-details-header__cta {
  align-items: center;
  color: color("cta");
  cursor: pointer;
  display: flex;
  gap: 4px;
  text-transform: uppercase;
}

.product-offering-details-item {
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  padding: 16px 0;
}
