@import "scss/main";

.beneficiaries__card-content {
  .text-tag {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .card-content__body-footer__button {
    width: 100%;

    @include breakpoint(medium) {
      max-width: 350px;
    }

    @include breakpoint(large) {
      max-width: 450px;
    }
  }
}
