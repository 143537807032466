@import "scss/main";

.savings-application-flow-ad-content {
  margin-top: -20px;
  margin-bottom: 40px;

  .card-content__multiple-ad-container {
    .card-content__multiple-ad-grid {
      display: flex;
      row-gap: initial;
    }

    .card-content__multiple-ad-carousel {
      display: none;
    }

    .card-content__multiple-ad {
      width: 100%;
      margin-bottom: 2.5rem;

      @include breakpoint(large) {
        width: calc(50% - 8px);
      }

      .card-content__multiple-ad-description {
        color: color("secondary-text");
      }

      .card-content__multiple-ad-img {
        width: auto;
        height: 60px;
      }
    }
  }
}

.insurance-application-flow-ad-content {
  padding-top: 48px;
}
