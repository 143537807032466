// stylelint-disable

$current-color-schema: "";

@import "functions";
@import "mixins";

//
// Color system
//

$gray-100: #f9f9f9 !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #566d76 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;

$grays: () !default;
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900,
  ),
  $grays
);

$mamey: #fa7b6a;
$cobalt: #5c43f5;
$royal: #442dd0;
$lemon: #f6de05;
$scallion: #02790f;
$lime: #44ab14;
$leaf: #21c288;
$orange: #fd832b;
$sky: #2e7ff9;
$eggplant: #d96ec1;
$watermelon: #ce1111;
$cherry: #f63c3c;
$coal: #1c1c1c;
$plaster: #f6f6f1;
$sand: #f0f0e4;
$kaolin: #ffffff;
$smoke: #5d5d5c;
$ashes: #b0b0a8;
$satin: #e8e8d8;
$silver: #d4d3dc;
$platinum: #c9c9c9;
$black: #000000;
$white: $kaolin;
$silver: #c8c6d6;

$primary: $mamey;
$primary-dark: $coal;
$primary-light: $plaster;
$cta: $cobalt;
$cta-pressed: $royal;
$accent: $lemon;
$success-dark: $scallion;
$success-light: $lime;
$error-dark: $watermelon;
$error-light: $cherry;
$secondary-dark: $sand;
$secondary-light: $kaolin;
$secondary-text: $smoke;
$placeholder-text: $ashes;
$tertiary-bg: $satin;
$tertiary-border: $silver;
$btn-inactive-dark: $platinum;
$btn-inactive-light: $silver;
$btn-hover: $black;
$focus-outline: #5d9dd5;

$colors: (
  "black": $black,
  "kaolin": $kaolin,
  "plaster": $plaster,
  "sand": $sand,
  "ashes": $ashes,
  "satin": $satin,
  "smoke": $smoke,
  "white": $white,
  "leaf": $leaf,
) !default;

$colors: map-merge(
  $colors,
  (
    "primary": $primary,
    "primary-dark": $primary-dark,
    "primary-light": $primary-light,
    "cta": $cta,
    "cta-pressed": $cta-pressed,
    "accent": $lemon,
    "success-light": $success-light,
    "success-dark": $success-dark,
    "error-light": $error-light,
    "error-dark": $error-dark,
    "secondary-dark": $secondary-dark,
    "secondary-light": $secondary-light,
    "secondary-text": $secondary-text,
    "placeholder-text": $placeholder-text,
    "tertiary-bg": $tertiary-bg,
    "tertiary-border": $tertiary-border,
    "btn-inactive-light": $btn-inactive-light,
    "btn-inactive-dark": $btn-inactive-dark,
    "btn-hover": $btn-hover,
    "focus-outline": $focus-outline,
  )
);

$theme-colors: $colors;

$box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12),
  0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1);

$dark-box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12),
  0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1);

// stylelint-enable

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
// $yiq-text-dark: $gray-900 !default;
$yiq-text-light: $white !default;

// Options

$breakpoint-separator: \@;
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-rounded: true !default;
$enable-shadows: true !default;
$enable-gradients: false !default; // Not supported by Shards.
$enable-transitions: true !default;
$enable-hover-media-query: false !default; // Deprecated, no longer affects any compiled CSS
$enable-fonts-import: true !default;

// Forms

$border-color: #becad6 !default;
$input-frozen-color: #abb6bf !default;

// Spacing

$spacer: 1.5rem !default; // ~ 24px
$wl-letter-spacing: -0.4px;
$c-letter-spacing: 0.0625rem;

// Body

$body-background: $white !default;
// $body-color: $gray-800 !default;

// Grid breakpoints

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
) !default;

@include _assert-ascending($container-max-widths, "$container-max-widths");

// Components

$line-height-lg: 1.5 !default;
$line-height-sm: 1.5 !default;

$border-width: 1px !default;

$border-radius: 0.375rem !default;
$border-radius-xl: 0.75rem !default;
$border-radius-lg: 0.5rem !default;
$border-radius-sm: 0.35rem !default;
$border-radius-xs: 0.125rem !default;

// Easings
$ease-in-out-circ: cubic-bezier(0.27, 0.01, 0.38, 1.06);

// Transitions
$transition-duration: 250ms;
$transition-base: all $transition-duration $ease-in-out-circ !default;
$transition-fade: opacity 200ms ease-in-out !default;
$transition-collapse: height 350ms ease-in-out !default;

// Fonts

$font-stack: (
  "ogg": (
    "Ogg",
    serif,
  ),
  "henderson-sans": (
    "Henderson Sans",
    "sans-serif",
  ),
  "monospace": (
    "Courier New",
    Courier,
    "Nimbus Sans L",
    monospace,
  ),
  "system": (
    -apple-system,
    system-ui,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Helvetica Neue",
    Helvetica,
    Arial,
    sans-serif,
  ),
);

// BASE FONT VARIABLES
$font-family-base: font-stack("system") !default;
$font-family-monospace: font-stack("monospace"), $font-family-base;
$font-family-system-first: font-stack("system");

// CUSTOM FONT VARIABLES
$ff-system: $font-family-base;
$ff-sans-serif: $font-family-system-first !default;

$ff-ogg: font-stack("ogg"), $font-family-base;
$ff-henderson-sans: font-stack("henderson-sans"), $font-family-base;

$ff-body: $ff-henderson-sans;

$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;
$font-weight-semi-bold: 600 !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;

$headings-margin-bottom: 0.75rem !default;

$headings-font-family: $ff-ogg !default;
$headings-font-weight: $font-weight-bold !default;
$headings-font-style: normal !default;
// $headings-color: $gray-900 !default;
$headings-letter-spacing: -0.0625rem !default; // ~1px

$header-font-style: normal;
$font-family-monospace: Consolas, "Liberation Mono", Courier, monospace;
$header-color: inherit;
$header-lineheight: 1.4;
$header-margin-bottom: 0.5rem;

$header-text-rendering: optimizeLegibility;
$small-font-size: 80%;
$paragraph-lineheight: 1.7;
$paragraph-margin-bottom: 1rem;
$paragraph-text-rendering: optimizeLegibility;
$anchor-color: #8a8a8a;
$anchor-color-hover: scale-color($anchor-color, $lightness: -14%);
$anchor-text-decoration: none;
$anchor-text-decoration-hover: none;

$font-sizes: (
  small: (
    h1: 19px,
    h2: 15px,
    h3: 11px,
  ),
  medium: (
    h1: 22px,
    h2: 18px,
    h3: 13px,
  ),
);
$paragraphs-margin-bottom: 1.75rem !default;

$lead-line-height: 1.875rem !default;

$small-font-size: 80% !default;

// $text-muted: $gray-600 !default;

$blockquote-font-size: 1.5rem !default;
$blockquote-footer-font-size: 1.125rem !default;
$blockquote-margin-bottom: 0.75rem !default;

$hr-border-color: rgba($black, 0.1) !default;
$hr-border-width: $border-width !default;
$hr-margin-y: 1.125rem !default;

$mark-padding: 0.2em !default;
$mark-bg: #fff09e !default;

// Icons
// $icon-color: $sk-red !default;

// Modals
$modal-background-color: $primary-light;
$modal-background-opacity: 0;
$modal-background: transparentize($modal-background-color, $modal-background-opacity);

$modal-overlay-background-color: $secondary-text;
$modal-overlay-background-opacity: 0.3;
$modal-overlay-background: transparentize(
  $modal-overlay-background-color,
  $modal-overlay-background-opacity
);
$modal-overlay-border-radius: 0;

// Navigation Bar
$header-height-sm: 80px;
$header-height-lg: 88px;

// Layout Column Width
$max-column-width: 400px;

// Typography

$header-line-height: 130%;
$line-height: 170%;
$wl-line-height: 140%;
$number-line-height: 100%;

$header-styles: (
  small: (
    "h1": (
      "font-size": 50,
      "line-height": $header-line-height,
    ),
    "h2": (
      "font-size": 42,
      "line-height": $header-line-height,
    ),
    "h3": (
      "font-size": 36,
      "line-height": $header-line-height,
    ),
    "h4": (
      "font-size": 30,
      "line-height": $header-line-height,
    ),
    "h5": (
      "font-size": 18,
      "line-height": $line-height,
    ),
    "h6": (
      "font-size": 16,
      "line-height": $line-height,
    ),
  ),
  medium: (
    "h1": (
      "font-size": 64,
      "line-height": $header-line-height,
    ),
    "h2": (
      "font-size": 50,
      "line-height": $header-line-height,
    ),
    "h3": (
      "font-size": 42,
      "line-height": $header-line-height,
    ),
    "h4": (
      "font-size": 32,
      "line-height": $header-line-height,
    ),
    "h5": (
      "font-size": 21,
      "line-height": $line-height,
    ),
    "h6": (
      "font-size": 18,
      "line-height": $line-height,
    ),
  ),
  xlarge: (
    "h1": (
      "font-size": 80,
      "line-height": $header-line-height,
    ),
    "h2": (
      "font-size": 64,
      "line-height": $header-line-height,
    ),
    "h3": (
      "font-size": 50,
      "line-height": $header-line-height,
    ),
    "h4": (
      "font-size": 36,
      "line-height": $header-line-height,
    ),
    "h5": (
      "font-size": 26,
      "line-height": $line-height,
    ),
    "h6": (
      "font-size": 21,
      "line-height": $line-height,
    ),
  ),
) !default;

$body-styles: (
  small: (
    "p1": (
      "font-size": 16,
      "line-height": $line-height,
    ),
    "p2": (
      "font-size": 14,
      "line-height": $line-height,
    ),
    "p3": (
      "font-size": 13,
      "line-height": $line-height,
    ),
    "p4": (
      "font-size": 12,
      "line-height": $line-height,
    ),
    "p5": (
      "font-size": 11,
      "line-height": $line-height,
    ),
    "p6": (
      "font-size": 10,
      "line-height": $line-height,
    ),
    "b1": (
      "font-size": 16,
      "line-height": $line-height,
    ),
    "b2": (
      "font-size": 14,
      "line-height": $line-height,
    ),
    "b3": (
      "font-size": 13,
      "line-height": $line-height,
    ),
    "b4": (
      "font-size": 12,
      "line-height": $line-height,
    ),
    "b5": (
      "font-size": 11,
      "line-height": $line-height,
    ),
    "b6": (
      "font-size": 10,
      "line-height": $line-height,
    ),
    "c1": (
      "font-size": 16,
      "font-weight": $font-weight-bold,
      "letter-spacing": $c-letter-spacing,
      "line-height": $line-height,
    ),
    "c2": (
      "font-size": 14,
      "font-weight": $font-weight-bold,
      "letter-spacing": $c-letter-spacing,
      "line-height": $line-height,
    ),
    "c3": (
      "font-size": 13,
      "font-weight": $font-weight-bold,
      "letter-spacing": $c-letter-spacing,
      "line-height": $line-height,
    ),
    "c4": (
      "font-size": 12,
      "font-weight": $font-weight-bold,
      "letter-spacing": $c-letter-spacing,
      "line-height": $line-height,
    ),
    "c5": (
      "font-size": 11,
      "font-weight": $font-weight-bold,
      "letter-spacing": $c-letter-spacing,
      "line-height": $line-height,
    ),
    "c6": (
      "font-size": 10,
      "font-weight": $font-weight-bold,
      "letter-spacing": $c-letter-spacing,
      "line-height": $line-height,
    ),
    "l1": (
      "font-size": 16,
      "font-weight": $font-weight-semi-bold,
      "line-height": $line-height,
    ),
    "l2": (
      "font-size": 14,
      "font-weight": $font-weight-semi-bold,
      "line-height": $line-height,
    ),
    "l3": (
      "font-size": 13,
      "font-weight": $font-weight-semi-bold,
      "line-height": $line-height,
    ),
    "l4": (
      "font-size": 12,
      "font-weight": $font-weight-semi-bold,
      "line-height": $line-height,
    ),
    "l5": (
      "font-size": 11,
      "font-weight": $font-weight-semi-bold,
      "line-height": $line-height,
    ),
    "l6": (
      "font-size": 10,
      "font-weight": $font-weight-semi-bold,
      "line-height": $line-height,
    ),
    "n1": (
      "font-size": 46,
      "font-weight": $font-weight-bold,
      "line-height": $line-height,
    ),
    "n2": (
      "font-size": 38,
      "font-weight": $font-weight-bold,
      "line-height": $line-height,
    ),
    "n3": (
      "font-size": 32,
      "font-weight": $font-weight-bold,
      "line-height": $line-height,
    ),
    "n4": (
      "font-size": 28,
      "font-weight": $font-weight-bold,
      "line-height": $line-height,
    ),
    "n5": (
      "font-size": 22,
      "font-weight": $font-weight-bold,
      "line-height": $line-height,
    ),
    "wl": (
      "font-size": 12,
      "font-weight": $font-weight-semi-bold,
      "line-height": $wl-line-height,
      "letter-spacing": $wl-letter-spacing,
    ),
  ),
  medium: (
    "p1": (
      "font-size": 16,
      "line-height": $line-height,
    ),
    "p2": (
      "font-size": 14,
      "line-height": $line-height,
    ),
    "p3": (
      "font-size": 13,
      "line-height": $line-height,
    ),
    "p4": (
      "font-size": 12,
      "line-height": $line-height,
    ),
    "p5": (
      "font-size": 11,
      "line-height": $line-height,
    ),
    "p6": (
      "font-size": 10,
      "line-height": $line-height,
    ),
    "b1": (
      "font-size": 16,
      "line-height": $line-height,
    ),
    "b2": (
      "font-size": 14,
      "line-height": $line-height,
    ),
    "b3": (
      "font-size": 13,
      "line-height": $line-height,
    ),
    "b4": (
      "font-size": 12,
      "line-height": $line-height,
    ),
    "b5": (
      "font-size": 11,
      "line-height": $line-height,
    ),
    "b6": (
      "font-size": 10,
      "line-height": $line-height,
    ),
    "c1": (
      "font-size": 16,
      "font-weight": $font-weight-bold,
      "line-height": $line-height,
    ),
    "c2": (
      "font-size": 14,
      "font-weight": $font-weight-bold,
      "line-height": $line-height,
    ),
    "c3": (
      "font-size": 13,
      "font-weight": $font-weight-bold,
      "line-height": $line-height,
    ),
    "c4": (
      "font-size": 12,
      "line-height": $line-height,
    ),
    "c5": (
      "font-size": 11,
      "font-weight": $font-weight-bold,
      "line-height": $line-height,
    ),
    "c6": (
      "font-size": 10,
      "font-weight": $font-weight-bold,
      "line-height": $line-height,
    ),
    "l1": (
      "font-size": 16,
      "font-weight": $font-weight-semi-bold,
      "line-height": $line-height,
    ),
    "l2": (
      "font-size": 14,
      "font-weight": $font-weight-semi-bold,
      "line-height": $line-height,
    ),
    "l3": (
      "font-size": 13,
      "font-weight": $font-weight-semi-bold,
      "line-height": $line-height,
    ),
    "l4": (
      "font-size": 12,
      "font-weight": $font-weight-semi-bold,
      "line-height": $line-height,
    ),
    "l5": (
      "font-size": 11,
      "font-weight": $font-weight-semi-bold,
      "line-height": $line-height,
    ),
    "l6": (
      "font-size": 10,
      "font-weight": $font-weight-semi-bold,
      "line-height": $line-height,
    ),
    "n1": (
      "font-size": 46,
      "font-weight": $font-weight-bold,
      "line-height": $line-height,
    ),
    "n2": (
      "font-size": 38,
      "font-weight": $font-weight-bold,
      "line-height": $line-height,
    ),
    "n3": (
      "font-size": 32,
      "font-weight": $font-weight-bold,
      "line-height": $line-height,
    ),
    "n4": (
      "font-size": 28,
      "font-weight": $font-weight-bold,
      "line-height": $line-height,
    ),
    "n5": (
      "font-size": 22,
      "font-weight": $font-weight-bold,
      "line-height": $line-height,
    ),
    "wl": (
      "font-size": 14,
      "font-weight": $font-weight-semi-bold,
      "line-height": $wl-line-height,
      "letter-spacing": $wl-letter-spacing,
    ),
  ),
  large: (
    "p1": (
      "font-size": 18,
      "line-height": $line-height,
    ),
    "p2": (
      "font-size": 16,
      "line-height": $line-height,
    ),
    "p3": (
      "font-size": 14,
      "line-height": $line-height,
    ),
    "p4": (
      "font-size": 13,
      "line-height": $line-height,
    ),
    "p5": (
      "font-size": 12,
      "line-height": $line-height,
    ),
    "p6": (
      "font-size": 11,
      "line-height": $line-height,
    ),
    "b1": (
      "font-size": 18,
      "line-height": $line-height,
    ),
    "b2": (
      "font-size": 16,
      "line-height": $line-height,
    ),
    "b3": (
      "font-size": 14,
      "line-height": $line-height,
    ),
    "b4": (
      "font-size": 13,
      "line-height": $line-height,
    ),
    "b5": (
      "font-size": 12,
      "line-height": $line-height,
    ),
    "b6": (
      "font-size": 11,
      "line-height": $line-height,
    ),
    "c1": (
      "font-size": 18,
      "font-weight": $font-weight-bold,
      "letter-spacing": $c-letter-spacing,
      "line-height": $line-height,
    ),
    "c2": (
      "font-size": 16,
      "font-weight": $font-weight-bold,
      "letter-spacing": $c-letter-spacing,
      "line-height": $line-height,
    ),
    "c3": (
      "font-size": 14,
      "font-weight": $font-weight-bold,
      "letter-spacing": $c-letter-spacing,
      "line-height": $line-height,
    ),
    "c4": (
      "font-size": 13,
      "font-weight": $font-weight-bold,
      "letter-spacing": $c-letter-spacing,
      "line-height": $line-height,
    ),
    "c5": (
      "font-size": 12,
      "font-weight": $font-weight-bold,
      "letter-spacing": $c-letter-spacing,
      "line-height": $line-height,
    ),
    "c6": (
      "font-size": 11,
      "font-weight": $font-weight-bold,
      "letter-spacing": $c-letter-spacing,
      "line-height": $line-height,
    ),
    "l1": (
      "font-size": 18,
      "font-weight": $font-weight-semi-bold,
      "line-height": $line-height,
    ),
    "l2": (
      "font-size": 16,
      "font-weight": $font-weight-semi-bold,
      "line-height": $line-height,
    ),
    "l3": (
      "font-size": 14,
      "font-weight": $font-weight-semi-bold,
      "line-height": $line-height,
    ),
    "l4": (
      "font-size": 13,
      "font-weight": $font-weight-semi-bold,
      "line-height": $line-height,
    ),
    "l5": (
      "font-size": 12,
      "font-weight": $font-weight-semi-bold,
      "line-height": $line-height,
    ),
    "l6": (
      "font-size": 11,
      "font-weight": $font-weight-semi-bold,
      "line-height": $line-height,
    ),
    "n1": (
      "font-size": 54,
      "font-weight": $font-weight-bold,
      "line-height": $line-height,
    ),
    "n2": (
      "font-size": 46,
      "font-weight": $font-weight-bold,
      "line-height": $line-height,
    ),
    "n3": (
      "font-size": 38,
      "font-weight": $font-weight-bold,
      "line-height": $line-height,
    ),
    "n4": (
      "font-size": 32,
      "font-weight": $font-weight-bold,
      "line-height": $line-height,
    ),
    "n5": (
      "font-size": 22,
      "font-weight": $font-weight-bold,
      "line-height": $line-height,
    ),
    "wl": (
      "font-size": 16,
      "font-weight": $font-weight-semi-bold,
      "line-height": $wl-line-height,
      "letter-spacing": $wl-letter-spacing,
    ),
  ),
);

$list-item-spacing: 0 15px;
