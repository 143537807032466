@import "scss/main";

.coverage-editor__container {
  padding-bottom: 120px;
  padding-left: 0;
  padding-right: 0;

  @include fadeIn();

  .coverage-editor__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    max-width: 100%;
    width: 100%;
    gap: 16px;

    @include breakpoint(large) {
      flex-direction: row;
      align-items: flex-start;
      gap: 60px;
    }
  }

  .coverage-editor__column {
    padding: 0px 24px;
    max-width: 100%;
    width: 100%;
    height: 100%;

    @include breakpoint(medium) {
      padding: 0;
      max-width: $max-column-width;
    }

    .coverage-editor__disclaimer-text-wrapper {
      margin-bottom: 24px;
    }

    .coverage-editor_disclaimer-text {
      color: color("secondary-text");
    }

    &.column-right {
      @include breakpoint(large) {
        position: sticky;
        top: calc(25px + #{$header-height-lg});
      }

      .quote-estimate {
        margin-bottom: 15px;
      }

      .coverage-editor__column-right-wrapper {
        width: 100%;
        max-width: $max-column-width;
      }

      .coverage-pending {
        .quote-estimate__cost {
          opacity: 0.5;
        }
      }
    }

    .coverage-editor__slider {
      margin: 36px 0;
    }

    .coverage-editor__slider--with-no-title {
      margin: 24px 0 36px;

      @include breakpoint(large) {
        margin: 32px 0 36px;
      }
    }

    .coverage-editor__wishes-list {
      margin-bottom: 20px;
      padding-top: 24px;

      .coverage-editor__wishes-list-title {
        margin: 32px 0 20px;
      }

      .coverage-editor__divider {
        @include breakpoint(small only) {
          position: absolute;
          left: 0;
          right: 0;
        }
      }

      .coverage-editor__wishes-item {
        pointer-events: none;
        max-width: 100%;

        .divider {
          display: none;
        }
      }
    }
  }

  .coverage-editor__premium-calculation {
    margin-bottom: 24px;
  }

  .coverage-editor__mobile-cta {
    align-items: center;
    background: color("primary-light");
    display: flex;
    flex-direction: column;
    opacity: 0;
    transition: opacity 0.25s ease-in;
    z-index: 40;

    &.fixed {
      opacity: 1;
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
    }

    .coverage-editor__mobile-cta-button-container {
      border-top: 1px solid color("tertiary-bg");
      display: flex;
      justify-content: center;
      padding: 16px 24px;
      width: 100%;
    }

    .coverage-editor__button {
      width: 100%;
      max-width: 350px;
    }
  }
}

.coverage-editor-ads {
  margin: 48px 0;
}

.coverage-editor-ads,
.coverage-editor-ads__cards,
.coverage-editor-ad__card {
  display: flex;
  flex-direction: column;
  gap: 24px 16px;
}

.coverage-editor-ads__cards {
  flex-flow: row wrap;
}

.coverage-editor-ad__card {
  gap: 12px;

  @include breakpoint(large) {
    width: calc(50% - 8px);
  }
}
