@import "scss/main";

.premium-calculation-modal {
  .modal__icon,
  .modal__header,
  .premium-calculation-modal__subtitle,
  .premium-calculation-modal__subtitle-list {
    margin-bottom: 16px;
  }

  .modal__subheader {
    margin-bottom: 24px;
  }

  .modal__btns {
    margin-top: 0;
  }
}

.premium-calculation-modal__subtitle.premium-calculation-modal__subtitle,
.premium-calculation-modal__footnote.premium-calculation-modal__footnote {
  text-align: left;
}

.premium-calculation-modal__footnote {
  color: color("text-tertiary");
}
