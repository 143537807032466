@import "scss/main";

.create-account {
  .create-account__terms,
  .create-account__checkbox {
    margin-top: 45px;

    @include breakpoint(large) {
      margin-top: 50px;
    }
  }

  .create-account__checkbox {
    font-size: 13px;

    @include breakpoint(large) {
      font-size: 14px;
    }
  }

  .create-account__btn {
    width: 100%;
  }
}
.create-account__subheader-el {
  color: color("secondary-text");
  margin-bottom: 25px;

  &.link {
    color: color("cta");
    cursor: pointer;

    &:hover {
      color: color("black");
    }
  }
}
