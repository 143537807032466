@import "scss/main";

.card-container {
  &.wysh-updates {
    padding: 16px;
    background-color: color("secondary-dark");
    max-width: 132px;
    min-width: 132px;
    width: 100%;
    height: 100px;

    @include breakpoint(medium) {
      max-width: 140px;
      min-width: 140px;
    }

    @include breakpoint(large) {
      max-width: 150px;
      min-width: 150px;
    }

    .card-content__header-wrapper {
      margin-bottom: 4px;
    }

    .card-content {
      .card-content__wysh--amount {
        display: flex;
        align-items: center;
        margin-bottom: 9px;

        @include breakpoint(medium) {
          margin-bottom: 11px;
        }

        .card-content__icon-wrapper {
          margin-right: 4px;
        }

        .card-content__amount-text {
          color: color("secondary-text");
        }
      }

      .card-content__date-wrapper {
        display: flex;
        justify-content: flex-end;

        .card-content__date-text {
          color: color("placeholder-text");
        }
      }
    }
  }
}
