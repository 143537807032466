@use "scss/main";

.form-value-props {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 12px;

  @include main.breakpoint(large) {
    flex-direction: row;
  }
}

.form-value-prop {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  .form-value-prop__description {
    color: main.color("secondary-text");
  }

  .form-value-prop__img {
    width: auto;
    height: 60px;
  }
}
