@import "scss/main";

.auth-layout {
  margin: 0 auto;
  max-width: 350px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 60px;

  @include breakpoint(medium) {
    padding-left: 0;
    padding-right: 0;
  }

  @include breakpoint(large) {
    max-width: 400px;
  }

  .btn {
    width: 100%;
    margin: 10px auto 0;
    padding: 20px 0;

    &:first-of-type {
      margin: 35px auto 10px 0;
    }
  }

  .login-header {
    max-width: 620px;
    margin-bottom: 5px;

    @include breakpoint(small) {
      margin-bottom: 45px;
    }

    .login-header__icon {
      margin-bottom: 15px;

      @include breakpoint(medium) {
        margin-bottom: 35px;
      }
    }

    .login-header__title {
      margin-bottom: 15px;

      @include breakpoint(large) {
        margin-bottom: 25px;
      }
    }

    &.login-header--has-subheader {
      h1 {
        margin-bottom: 25px;
      }
    }

    .login-header__button {
      margin: 0;
      padding: 5px 0;
      text-transform: none;
      width: auto;
    }
  }

  .auth-layout__forgot-pw {
    padding: 0;
    margin-top: 15px;
  }
}
