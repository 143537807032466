@import "scss/main";

.flow-review-question {
  .flow-review-field:not(:last-child) {
    margin-bottom: 4px;
  }
}

.flow-review-question__title {
  margin-bottom: 20px;
}