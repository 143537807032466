@import "scss/main";

.form-item-image--centered {
  .svg-wrapper {
    justify-content: center;
  }
}

.form-item-image--leading {
  .svg-wrapper {
    justify-content: flex-start;
  }
}

.form-item-image--trailing {
  .svg-wrapper {
    justify-content: flex-end;
  }
}
