@import "scss/main";

.test-modal {
  max-width: 600px;
  z-index: 1050;
  margin: 0 auto;

  .test-modal-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0px;
  }

  h3 {
    text-align: center;
    margin-bottom: 20px;
  }

  p {
    text-align: center;
  }

  .test-modal__btns {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 25px;

    button {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
