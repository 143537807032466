@import "scss/main";

.radio__group__inputs {
  display: flex;
}

.radio__group__inputs--vertical {
  flex-direction: column;
}

.radio__group__inputs--border-bottom {
  .radio {
    border-bottom: 1px solid color("tertiary-bg");
    padding-top: 25px;
    padding-bottom: 25px;
    margin-bottom: 0;

    &:last-child {
      border: 0;
    }
  }
}
