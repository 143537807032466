@import "scss/main";

.address_input__suggestions_container {
  position: relative;
  width: 100%;
  height: max-content;
}

.address-verifications-suggestions__list {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 220px;
  min-height: 70px;
  max-height: 50vw;
  overflow: auto;
  background-color: color("kaolin");
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);

  @include breakpoint(medium) {
    height: auto;
    max-height: 426px;
  }
}

.form__section {
  .address-verifications-suggestions__list {
    position: relative;
  }
}

.address-suggestion-item {
  padding-left: 10px;

  .list-item__text {
    .variable-text_tag {
      font-weight: bold;
    }
  }
}

.address_input__container .address-input__cta--clear-form {
  text-transform: none;
}
