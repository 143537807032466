@import "scss/main";

.divider {
  max-width: none;
  height: 1px;
  margin: 0;
  background-color: $tertiary-bg;
  list-style: none;
  border-bottom: none;

  &.divider--absolute {
    position: absolute;
    left: 0;
    right: 0;
  }

  &.divider--vertical {
    align-self: stretch;
    width: 1px;
    height: auto;
    &.divider--absolute {
      left: auto;
      right: auto;
      top: 0;
      bottom: 0;
    }
  }

  &.divider--bold {
    height: 8px;
  }

  &.divider--silver {
    background-color: $silver;
  }
}
