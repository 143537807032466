@import "scss/main";

.wysh-category__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .wysh-category__add-wyshes-item {
    &.card-container {
      margin: 8px 0px;
      max-width: 48%;

      @include breakpoint(medium) {
        margin: 16px 0px;
        max-width: 47%;
      }
    }
  }

  &.fade-in {
    .wysh-category__add-wyshes-item {
      @for $i from 1 through 10 {
        &:nth-of-type(#{$i}) {
          @include fadeInDown(1, 0.8s, #{$i * 0.05s});
        }
      }
    }
  }
}
