@import "scss/main";

.fullscreen-type {
  .modal,
  .modal .modal-wrapper {
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    height: 100%;
    padding: 0;
  }

  .full-screen-modal {
    &.modal-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &:focus {
      outline: none;
    }
  }
}

.full-screen-modal-header {
  display: flex;
  width: 100%;
  padding: 0 24px;
  height: $header-height-sm;

  @include breakpoint(medium) {
    padding: 0 48px;
    height: $header-height-lg;
  }
}

.full-screen-modal__close {
  margin-bottom: 24px;
}

.full-screen-modal_close-icon {
  @include breakpoint(medium) {
    .icon-container {
      width: 32px;
      max-height: 32px;
    }
  }
}

.full-screen-modal__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 24px;

  @include breakpoint(large) {
    flex-basis: calc(50% + #{$header-height-lg} + 32px);
  }
}

.full-screen-modal__content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex: 1;

  @include breakpoint(large) {
    flex-direction: row;
    gap: 60px;
  }
}

.full-screen-modal__content {
  max-width: $max-column-width;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  @include breakpoint(medium) {
    align-items: flex-start;
  }
}

.full-screen-modal__image {
  max-width: 100%;
}

.full-screen-modal__subheader {
  color: color("secondary-text");
}
