@import "scss/main";

.link {
  color: color("cta");
  font-size: 14px;
  transition: color 0.2s;

  &:hover {
    color: color("black");
  }

  @include breakpoint(large) {
    font-size: 16px;
  }
}

.link--disabled {
  pointer-events: none;
  opacity: 0.5;
}

.link-text {
  color: color("cta");
  transition: color 0.2s;
  cursor: pointer;

  &:visited {
    color: color("cta-pressed");
  }

  &:hover {
    color: color("black");
  }
}
