@import "scss/main";

.wysh-number__indicator {
  background: color("white");
  color: color("black");
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @include breakpoint(medium) {
    margin-top: -40px;
    margin-bottom: 40px;
  }
}

.wysh-allocation__knob-text__wrapper {
  text-align: center;
}

.wysh-allocation__container {
  .wysh-allocation__wrapper {
    .wysh-allocation__content {
      padding: 35px 24px 24px;

      @include breakpoint(large) {
        padding: 48px 40px 24px;
      }
    }

    .wysh-allocation__summary {
      border-bottom: 8px solid color("sand");

      .wysh-allocation__summary-text {
        .wysh-allocation__summary-title {
          margin-bottom: 24px;
        }
      }

      .wysh-allocation__summary-content {
        margin-top: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

.wysh-allocation__cta-wrapper {
  .wysh-allocation__cta-button {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}