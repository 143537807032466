@import "scss/main";

.review-offerings__header {
  background-color: color("secondary-dark");
  margin-top: $header-height-sm;
  height: 440px;

  @include fadeIn(1s, 0.5s);

  @include breakpoint(medium) {
    margin-top: $header-height-lg;
  }
}

.review-offerings__content {
  margin: 0 auto;
  padding: 0 25px;

  @include fadeIn();
}

.review-offerings__title-container {
  max-width: 600px;
  margin: 0 auto;
}

.review-offerings__title,
.review-offerings__pretitle {
  margin-bottom: 16px;

  @include breakpoint(medium) {
    text-align: center;
  }
}

.review-offerings__subtitle {
  margin-bottom: 42px;
  color: color("secondary-text");

  @include breakpoint(medium) {
    text-align: center;
  }
}

.review-offerings__offerings {
  display: flex;
  flex-direction: column;
  align-items: center;

  .offer-card__container {
    max-width: 485px;
    width: 100%;
  }
}
