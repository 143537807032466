.dynamic-form-item__decoration--illustration {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px;

  .dynamic-form-item__decoration--image {
    width: 100%;
  }
}
