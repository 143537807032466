@import "scss/main";

.image-background-layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 230px 0 25px;

  @include breakpoint(large) {
    padding-top: calc(90px + #{$header-height-lg});
  }

  .image-background-layout__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 90%;
    width: 100%;
    margin: 0 auto;

    @include breakpoint(medium) {
      max-width: 100%;
      justify-content: space-between;
    }

    @include breakpoint(large) {
      max-width: 1000px;
      justify-content: center;
    }

    @include breakpoint(xlarge) {
      max-width: 1200px;
    }

    .image-background-layout__left {
      display: flex;
      justify-content: center;
      flex-direction: column;
      z-index: 40;
      max-width: 100%;
      position: relative;
      box-sizing: border-box;

      @include breakpoint(medium) {
        max-width: 350px;
        margin-left: 100px;
      }

      @include breakpoint(large) {
        max-width: 400px;
        margin-right: 50px;
      }

      &:before {
        content: "";
        position: absolute;
        top: -35%;
        left: -85px;
        width: 170px;
        height: 180px;
        padding-right: 1em;
        background: url("../../../assets/images/blobs/BlogGray.svg") no-repeat;
        background-repeat: no-repeat;
        z-index: -20;

        @include breakpoint(medium) {
          top: -30%;
        }
      }
    }

    .image-background-layout__right {
      width: 100%;
      max-width: 100%;
      height: 100%;
      position: absolute;
      top: 0;

      @include breakpoint(large) {
        position: initial;
        max-width: 500px;
      }

      .image-background-layout__image-wrapper {
        height: 100%;
        position: relative;
        overflow: hidden;

        .image-background-layout__image {
          width: 100%;
          max-width: 320px;
          position: absolute;
          overflow: hidden;

          @include breakpoint(medium) {
            max-width: 400px;
          }

          @include breakpoint(large) {
            max-width: 460px;
            position: static;
          }
        }
      }
    }

    .image-background-layout__header {
      margin-bottom: 15px;

      @include breakpoint(medium) {
        margin-bottom: 25px;
      }

      @include breakpoint(large) {
        margin-bottom: 35px;
      }
    }

    .image-background-layout__subheader {
      color: color("secondary-text");
    }

    .image-background-layout__header-button {
      margin-top: 25px;

      @include breakpoint(medium) {
        margin-bottom: 35px;
      }
    }
  }
}
