@import "scss/main";

.resend-code-modal {
  .verify-phone__terms-disclaimer {
    @include breakpoint(medium) {
      margin-left: 16px;
      margin-right: 16px;
    }
  }
}
