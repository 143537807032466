@import "scss/main";

.hero-image-header {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: -10;

  width: 100%;
  height: 320px;

  background-position: center bottom;
  background-repeat: no-repeat;

  @include breakpoint(medium) {
    height: 350px;
  }
  @include breakpoint(large) {
    height: 400px;
  }
}
