@import "scss/main";

.home-header {
  display: flex;
  justify-content: center;
  padding: 32px 24px;
  padding-top: calc(32px + #{$header-height-sm});

  @include breakpoint(large) {
    padding: 32px 24px;
    padding-top: calc(48px + #{$header-height-lg});
  }
}

.home-header__content {
  width: 100%;
  max-width: $max-column-width;

  @include breakpoint(large) {
    max-width: 860px;
    margin: auto;
  }
}

.home-header__text {
  max-width: $max-column-width;
}
