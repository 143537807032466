@import "scss/main";

.content-wrapper {
  max-width: $max-column-width;
  width: 100%;
  padding: 0;

  @include breakpoint(medium) {
    padding: 0;
  }
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  padding: 0 24px;
  gap: 28px;

  @include breakpoint(large) {
    flex-direction: row;
    align-items: flex-start;
    gap: 60px;
  }
}
