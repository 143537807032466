@import "scss/main";

.header-logo__container {
  align-items: center;
  display: inline-flex;
}

.header-logo__logo {
  width: 35px;

  .icon-wrapper {
    @include breakpoint(medium) {
      width: 84px;
    }
  }
}

.header-logo__co-brand-container {
  align-items: center;
  display: inline-flex;
  margin-left: 2px;
}

.header-logo__co-brand-logo {
  height: 24px;
  margin-left: 2px;

  @include breakpoint(medium) {
    height: 30px;
  }
}
