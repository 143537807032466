.account-close-modal {
  .modal__icon,
  .modal__header,
  .modal__subheader {
    align-self: start;
  }

  .modal__header,
  .modal__subheader {
    text-align: left;
  }
}
