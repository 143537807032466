@import "scss/main";

.quote-header {
  display: flex;
  justify-content: center;
  background: color("primary-dark");
  width: 100%;
  height: 46px;
  position: fixed;
  top: $header-height-sm;
  z-index: 1000;

  @include breakpoint(medium) {
    top: $header-height-lg;
  }

  @include fadeIn(1, 0.5s);

  .quote-header__wrapper {
    padding: 0px 14px;
    max-width: 320px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include breakpoint(medium) {
      max-width: 350px;
    }

    .quote-header__content-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      .quote-header__content {
        margin-right: 5px;
      }
    }

    .text-tag {
      margin-bottom: 0;
    }

    .quote-header__wishes-wrapper {
      display: none;
    }
  }

  &.hide-coverage {
    .quote-header__wrapper {
      justify-content: center;
    }

    .quote-header__coverage-wrapper {
      display: none;
    }
  }

  &.show-wishes {
    .quote-header__wrapper {
      justify-content: space-between;
    }

    .quote-header__wishes-wrapper {
      display: flex;
    }
  }
}
