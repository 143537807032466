@import "scss/main";

.payment-confirmation {
  @include fadeIn();

  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
}

.payment-confirmation-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 12px;

  @include breakpoint(medium) {
    margin-bottom: 24px;
  }
}

.payment-confirmation-header__title {
  margin-bottom: 16px;
}

.payment-confirmation-header__subheader {
  color: color("secondary-text");
  margin-bottom: 12px;

  @include breakpoint(medium) {
    margin-bottom: 0;
  }
}

.payment-confirmation-details {
  display: flex;
  flex-direction: column;
  background-color: color("secondary-dark");
  max-width: 582px;
  margin: 0 auto 24px;
  padding: 16px 16px 16px;
  border-radius: 10px;

  @include breakpoint(medium) {
    padding: 16px 24px 24px;
    margin-bottom: 32px;
  }

  .payment-confirmation-details__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
    max-width: 100%;
    background-color: inherit;
    margin-bottom: 8px;

    @include breakpoint(medium) {
      justify-content: flex-start;
      margin-bottom: 12px;
    }

    .payment-confirmation-details__list-item {
      padding: 0;
      flex-basis: 100%;
      margin-bottom: 16px;
      border-color: transparent;
      min-height: auto;

      @include breakpoint(medium) {
        flex-basis: calc(50% - 30px);
        margin-bottom: 16px;
      }

      .list-item__content {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .list-item__left-container {
          max-width: initial;
        }

        .list-item__right-container_text {
          margin: 0;
        }
      }
    }
  }

  .payment-confirmation-details__download_cta {
    width: fit-content;

    .payment-confirmation-details__download_cta__button {
      padding-left: 0;
      padding-right: 0;
      text-transform: initial;

      .btn-text {
        font-size: 14px;
      }
    }
  }
}

.payment-confirmation-details__header {
  border-bottom: 1px solid color("ashes");
  margin-bottom: 16px;
  padding-bottom: 12px;
}

.payment-confirmation-policy-details__cta {
  width: 100%;
  margin: 0 auto;
  max-width: 240px;
}

.payment-confirmation-customer-support {
  display: flex;
  flex-direction: column;
  margin: 16px auto;

  @include breakpoint(medium) {
    align-items: center;
  }
}

.payment-confirmation-customer-support__header {
  text-align: center;
  margin-top: 12px;
  margin-bottom: 8px;
}

.payment-confirmation-customer-support__cta {
  background-color: inherit;
  border: 0;
  color: color("cta");
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  display: inline;
}

.payment-confirmation-customer-support__additional-info {
  color: color("secondary-text");
  text-align: center;
}
