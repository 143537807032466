@import "scss/main";

.honesty-pledge {
  .message-layout__content-text-wrapper + & {
    margin-top: -20px;
  }
}

.honesty-pledge__content {
  margin-bottom: 40px;
}

.honesty-pledge__company-name,
.honesty-pledge__header,
.honesty-pledge__subheader,
.honesty-pledge__agreement-content:not(:last-child) {
  margin-bottom: 24px;
}

.honesty-pledge__agreement-content {
  color: color("smoke");
}

.honesty-pledge__agreement-header {
  margin-bottom: 8px;
}
