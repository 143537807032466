.card-btn-input {
  display: flex;
  justify-content: center;
  min-width: 275px;
}

.card-btn-input--disabled {
  pointer-events: none;
  cursor: none;
  opacity: 0.5;
}
