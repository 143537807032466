@import "scss/main";

.carousel-container {
  padding-bottom: 50px;

  .carousel__header--wrapper {
    margin-bottom: 35px;

    .carousel__header--text {
      text-transform: uppercase;

      .text-tag {
        margin: 0;
      }
    }

    .carousel__arrow--wrapper {
      display: none;

      &.carousel__arrow--small {
        display: flex;
      }

      @include breakpoint(medium) {
        &.carousel__arrow--medium {
          display: flex;
        }
      }

      @include breakpoint(large) {
        &.carousel__arrow--large {
          display: flex;
        }
      }

      .carousel__arrow {
        border-radius: 50%;
        width: 26px;
        height: 26px;
        background: color("secondary-dark");
        margin: 0 10px;
        -webkit-tap-highlight-color: transparent;

        .slider-arrow__icon {
          transition: $transition-fade;

          .svg-wrapper {
            display: flex;
          }
        }

        &.inactive {
          pointer-events: none;

          .slider-arrow__icon {
            opacity: 0.4;
          }
        }

        &.next--arrow {
          transform: rotateY(180deg);
        }
      }
    }
  }

  .slick-slider {
    &.mx-4 {
      .slick-list {
        .slick-track {
          .slick-slide {
            > div {
              margin: 0 4px;
            }
          }
        }
      }
    }

    .slick-list {
      overflow: visible !important;

      .slick-track {
        display: flex;
        gap: 10px;

        .slick-slide {
          float: none;
        }
      }
    }

    .slider-arrow {
      display: none !important;
    }
  }
}
