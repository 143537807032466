@import "scss/main";

.header {
  align-items: center;
  background-color: color("primary-light");
  border-bottom: 1px solid color("secondary-dark");
  display: grid;
  height: $header-height-sm;
  padding: 0 24px;
  position: fixed;
  top: 0;
  transition: background 0.25s ease, box-shadow 0.25s ease;
  width: 100%;
  z-index: 1030;

  &:not(.header--immersive) {
    gap: 32px;
    grid-template-columns: 32px 1fr 32px;
  }

  @include breakpoint(medium) {
    height: $header-height-lg;
  }

  @include breakpoint(768px) {
    &:not(.header--immersive) {
      grid-template-columns: 96px 1fr 96px;
    }
  }

  @include breakpoint(960px) {
    padding: 0 48px;

    &:not(.header--immersive) {
      grid-template-columns: 96px 1fr 208px;
    }
  }
}

.header--immersive {
  grid-template-columns: 96px 1fr 96px;

  @include breakpoint(960px) {
    grid-template-columns: 208px 1fr 208px;
  }

  .header__wysh-builder-cta {
    margin-right: 8px;

    @include breakpoint(large) {
      margin-right: 16px;
    }

    .btn-text {
      white-space: nowrap;
    }
  }
}

.header--transparent {
  background-color: transparent;
  box-shadow: none;

  &:has(.header-menu--open) {
    background: color("primary-light");
  }
}

.header__section {
  align-items: center;
  display: flex;
}

.header__section--left {
  .header__logo {
    padding: 0 8px;

    @include breakpoint(large) {
      padding: 0 16px;
    }
  }
}

.header__section--center {
  justify-self: center;

  @include breakpoint(960px) {
    justify-self: start;
  }

  .header--immersive & {
    justify-self: center;
  }
}

.header__section--right {
  justify-self: end;
}

.header__login {
  color: color("primary-dark");
  padding: 12px;
  margin: 0 8px;
  text-transform: capitalize;
}

.header__help,
.header__account {
  .header--authenticated:not(.header--immersive) & {
    display: none;

    @include breakpoint(960px) {
      display: flex;
    }
  }
}

.header__help {
  &:not(:last-child) {
    margin-right: 24px;
  }
}

.header__exit {
  display: none;
  padding: 0 8px 0 0;

  @include breakpoint(large) {
    display: block;
    padding: 0 16px 0 0;
  }
}

.header__menu {
  &.header__menu {
    @include breakpoint(960px) {
      display: none;
    }
  }
}
