@import "scss/main";

.header-account-button {
  height: 40px;
  padding: 0;

  path {
    fill: color("primary-dark");
  }

  &.header-account-button--active,
  &:hover {
    path {
      fill: color("cta");
    }
  }
}

.header-account-button__icon {
  &.icon-container {
    max-height: 32px;
    max-width: 32px;
  }
}
