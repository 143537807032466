@import "scss/main";

.wishes-intro__container {
  @include fadeIn();

  .two-col-layout__wrapper {
    padding-top: 50px;
  }

  .two-col-layout__right {
    .two-col-layout__header {
      margin: 0 !important;
    }
  }

  .wishes-intro__header {
    margin-bottom: 8px;

    @include breakpoint(medium) {
      margin-bottom: 25px;
    }
  }

  .wishes-intro__body-text {
    margin-bottom: 15px;

    @include breakpoint(medium) {
      margin-bottom: 25px;
    }
  }

  .wishes-intro__content-list {
    margin-bottom: 25px;

    @include breakpoint(medium) {
      margin-bottom: 35px;
    }

    .blob-item__container {
      @include fadeIn();
    }
  }

  .wishes-intro__button-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 24px;

    @include breakpoint(medium) {
      margin-top: 36px;
    }

    .wishes-intro__button {
      width: 100%;
      margin-bottom: 8px;
    }
  }
}
