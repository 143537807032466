@use "scss/main" as *;

.insurance-profile-review-info {
  padding-top: 16px;

  .insurance-profile-review-info__header,
  .insurance-profile-review-info__item:not(:last-child),
  .insurance-profile-review-info__info-message:not(:last-child) {
    gap: 12px;
    margin-bottom: 12px;
  }

  .insurance-profile-review-info__item {
    gap: 12px;
  }

  .insurance-profile-review-info-item__value {
    white-space: pre;
  }
}

.insurance-profile-review-info__header {
  border-bottom: 1px solid color("tertiary-bg");
  padding-bottom: 16px;
}

.insurance-profile-review-info__item {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.insurance-profile-review-info-item__label {
  color: color("secondary-text");
}

.insurance-profile-review-info-item__value {
  text-align: right;
}

.insurance-profile-review-info__message {
  color: color("placeholder-text");
  text-align: center;
}

.personal-info-header__wrapper {
  border-bottom: 1px solid color("tertiary-bg");
  gap: 12px;
  margin-bottom: 12px;
  padding-bottom: 16px;


  .form-item-list-row__column--trailing {
    width: min-content;
    justify-self: self-end;

    .form-item-image--trailing {
      justify-self: self-end;

      .svg-wrapper {
        path {
          fill: color("cta");
        }
      }
    }
  }
}
