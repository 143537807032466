@import "scss/main";

// Hack to remove autofill yellow background color
input:-webkit-autofill {
  box-shadow: 0 0 0 1000px color("secondary-dark") inset;
  -webkit-box-shadow: 0 0 0 1000px color("secondary-dark") inset;
}

input:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px color("secondary-dark") inset;
  -webkit-box-shadow: 0 0 0 1000px color("tertiary-bg") inset;
}

// Remove number picker in type=number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input {
  border-radius: 0;
}
