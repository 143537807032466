@import "scss/main";

.carousel__arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  background: color("tertiary-bg");
  margin: 0 10px;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;

  .slider-arrow__icon {
    transition: $transition-fade;

    .svg-wrapper {
      display: flex;
    }
  }

  &.inactive {
    pointer-events: none;

    .slider-arrow__icon {
      opacity: 0.4;
    }
  }

  &.next--arrow {
    transform: rotateY(180deg);
  }
}
