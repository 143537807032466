@use "scss/main";

.wyshes-card-slider {
  display: flex;
  position: relative;
  overflow: hidden;

  .carousel-container {
    width: 100%;
    padding: 0;
  }

  .carousel__header--wrapper {
    display: none;
  }

  .wyshes-card {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    max-width: 132px;
    min-width: 132px;
    padding: 16px 8px;
    gap: 4px;
    border-radius: 12px;
    cursor: grab;

    .icon-container {
      height: 32px;
      min-width: 32px;
    }
  }
}

.wyshes-card-slider__cta {
  display: flex;
  height: 100%;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  right: 0px;
  box-shadow: main.box-shadow(xlarge);
}
