@import "scss/main";

.header-logo {
  align-items: center;
  display: inline-flex;
}

.header-logo__image {
  display: none;
}

.header-logo__image--sm {
  display: block;

  .header:not(.header--immersive) & {
    @include breakpoint(768px) {
      display: none;
    }
  }
}

.header-logo__image--lg {
  width: 83px;

  .header:not(.header--immersive) & {
    @include breakpoint(768px) {
      display: block;
    }
  }
}

.header-logo__co-brand {
  align-items: center;
  display: inline-flex;
  margin-left: 5px;
}

.header-logo__co-brand-logo {
  height: 28px;
  margin-left: 5px;
}
