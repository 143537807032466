@import "scss/main";

.pincode-input-container {
  display: flex;

  .pincode-input-text {
    margin: 0 6px !important;
    border-top: 0 !important;
    border-right: 0 !important;
    border-left: 0 !important;
    color: color("primary-dark");
    border-bottom: 1px solid color("primary-dark");
    width: 35px !important;
    font-size: 21px;
    font-weight: 600;

    @include breakpoint(medium) {
      margin: 0 7px !important;
      width: 45px !important;
    }

    &:first-child {
      margin-left: 0 !important;
    }
    &:last-child {
      margin-right: 0 !important;
    }
  }
}

.pin {
  display: inline-block;
  margin-top: 15px;
  position: relative;

  .loader-container {
    position: absolute;
    top: 10px;
    right: 0;
    left: 0;
    margin: auto;
  }

  &.disabled {
    .pincode-input-container {
      .pincode-input-text {
        color: color("placeholder-text");
        border-color: color("placeholder-text");
      }
    }

    .pin__message {
      color: color("placeholder-text");
    }
  }
}

.pin__message {
  margin-top: 25px;
}

.pin--error {
  .pincode-input-text {
    color: color("error-dark") !important;
  }

  .pin__message {
    color: color("error-dark");
  }

  .pincode-input-text {
    border-bottom: 1px solid color("error-dark") !important;
  }
}
