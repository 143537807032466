@use "scss/main" as *;

@include generateSpacingClasses("space");

.dynamic-form__container {
  display: flex;
  justify-content: center;
  flex-direction: column;

  @include fadeIn();
}

.dynamic-form--modal {
  position: fixed;
}

.dynamic-form__form {
  fieldset {
    border: none;
    margin: 0;
    padding: 0;

    &:disabled {
      opacity: 0.8;
      pointer-events: none;
    }
  }
}

.dynamic-form__error-message.form-error {
  margin: auto;
  width: 100%;
  max-width: $max-column-width;

  &.form-error--active {
    padding-bottom: 24px;
  }
}
