// Base Typography
// - - - - - - - - - - - - - - -
// These are styles applied to basic HTML tags, including:
//   - Paragraphs <p>
//   - Bold/italics <b> <strong> <i> <em>
//   - Small text <small>
//   - Headings <h1>-<h6>
//   - Anchors <a>
//   - Dividers <hr>
//   - Lists <ul> <ol> <dl>

$header-font-family: $headings-font-family;
$header-font-weight: $headings-font-weight;
$header-font-style: $headings-font-style;
$header-color: inherit !default;
$header-lineheight: 130% !default;
$header-margin-bottom: $headings-margin-bottom !default;

// $header-styles map is built from $header-sizes in order to ensure downward compatibility
// when $header-sizes is depreciated, $header-styles needs to get !default values like settings.scss
@function build_from_header-sizes($header-sizes) {
  @warn 'Note, that $header-sizes has been replaced with $header-styles. $header-sizes still works, but it is going to be depreciated.';
  $header-styles: ();
  @each $size, $headers in $header-sizes {
    $header-map: ();
    @each $header, $font-size in $headers {
      $header-map: map-merge(
        $header-map,
        (
          $header: (
            "font-size": $font-size,
          ),
        )
      );
    }
    $header-styles: map-merge(
      $header-styles,
      (
        $size: $header-map,
      )
    );
  }
  @return $header-styles;
}

// If it exists $headers-sizes is used to build $header-styles. See the documentation.
@if variable-exists(header-sizes) {
  $header-styles: build_from_header-sizes($header-sizes);
}

$header-text-rendering: optimizeLegibility !default;
$small-font-size: 80% !default;
$paragraph-lineheight: 1.6 !default;
$paragraph-margin-bottom: $paragraphs-margin-bottom !default;
$paragraph-text-rendering: optimizeLegibility !default;
$anchor-color: $primary-color !default;
$anchor-color-hover: scale-color($anchor-color, $lightness: -14%) !default;
$anchor-text-decoration: none !default;
$anchor-text-decoration-hover: none !default;
$hr-width: $global-width !default;
$hr-border: $hr-border-width solid $hr-border-color !default;
$hr-margin: rem-calc(20) auto !default;
$list-lineheight: $paragraph-lineheight !default;
$list-margin-bottom: $paragraph-margin-bottom !default;
$list-style-type: disc !default;
$list-style-position: outside !default;
$list-side-margin: 1.25rem !default;
$list-nested-side-margin: 1.25rem !default;

@mixin typography {
  // Typography resets
  div,
  dl,
  dt,
  dd,
  ul,
  ol,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  pre,
  form,
  p,
  blockquote,
  th,
  td {
    margin: 0;
    padding: 0;
  }

  // Paragraphs
  p {
    margin-bottom: $paragraph-margin-bottom;
    font-size: inherit;
    line-height: $paragraph-lineheight;
    text-rendering: $paragraph-text-rendering;
  }

  // Emphasized text
  em,
  i {
    font-style: italic;
    line-height: inherit;
  }

  // Strong text
  strong,
  b {
    font-weight: $font-weight-bold;
    line-height: inherit;
  }

  // Small text
  small {
    font-size: $small-font-size;
    line-height: inherit;
  }

  // Headings
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    font-family: $header-font-family;
    font-style: $header-font-style;
    font-weight: $header-font-weight;
    color: $header-color;
    text-rendering: $header-text-rendering;
  }

  h5,
  .h5,
  h6,
  .h6 {
    font-family: $ff-body;
  }

  // Heading styles
  @each $size, $headers in $header-styles {
    @include breakpoint($size) {
      @each $header, $header-defs in $headers {
        $font-size-temp: 1rem;
        #{$header},
        .#{$header} {
          @if map-has-key($header-defs, font-size) {
            $font-size-temp: rem-calc(map-get($header-defs, font-size));
            font-size: $font-size-temp;
          } @else if map-has-key($header-defs, fs) {
            $font-size-temp: rem-calc(map-get($header-defs, fs));
            font-size: $font-size-temp;
          } @else if $size == $-zf-zero-breakpoint {
            font-size: $font-size-temp;
          }
          @if map-has-key($header-defs, line-height) {
            line-height: unitless-calc(map-get($header-defs, line-height), $font-size-temp);
          } @else if map-has-key($header-defs, lh) {
            line-height: unitless-calc(map-get($header-defs, lh), $font-size-temp);
          } @else if $size == $-zf-zero-breakpoint {
            line-height: unitless-calc($header-lineheight, $font-size-temp);
          }

          @if map-has-key($header-defs, margin-top) {
            margin-top: rem-calc(map-get($header-defs, margin-top));
          } @else if map-has-key($header-defs, mt) {
            margin-top: rem-calc(map-get($header-defs, mt));
          } @else if $size == $-zf-zero-breakpoint {
            margin-top: 0;
          }
          @if map-has-key($header-defs, margin-bottom) {
            margin-bottom: rem-calc(map-get($header-defs, margin-bottom));
          } @else if map-has-key($header-defs, mb) {
            margin-bottom: rem-calc(map-get($header-defs, mb));
          } @else if $size == $-zf-zero-breakpoint {
            margin-bottom: rem-calc($header-margin-bottom);
          }
        }
      }
    }
  }

  // Links
  a {
    line-height: inherit;
    color: $anchor-color;
    text-decoration: $anchor-text-decoration;

    cursor: pointer;

    &:hover,
    &:focus {
      color: $anchor-color-hover;
      @if $anchor-text-decoration-hover != $anchor-text-decoration {
        text-decoration: $anchor-text-decoration-hover;
      }
    }

    img {
      border: 0;
    }
  }

  // Horizontal rule
  hr {
    clear: both;

    max-width: $hr-width;
    height: 0;
    margin: $hr-margin;

    border-top: 0;
    border-right: 0;
    border-bottom: $hr-border;
    border-left: 0;
  }

  // Lists
  ul,
  ol,
  dl {
    margin-bottom: $list-margin-bottom;
    list-style-position: $list-style-position;
    line-height: $list-lineheight;
  }

  // List items
  li {
    font-size: inherit;
  }

  // Unordered lists
  ul {
    margin-left: $list-side-margin;
    list-style-type: $list-style-type;
  }

  // Ordered lists
  ol {
    margin-left: $list-side-margin;
  }

  // Nested unordered/ordered lists
  ul,
  ol {
    & & {
      margin-left: $list-nested-side-margin;
      margin-bottom: 0;
    }
  }

  // Figures
  figure {
    margin: 0;
  }
}

.fw-normal {
  font-weight: normal;
}