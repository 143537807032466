@import "scss/main";

.offer-card {
  border: 2px solid color("primary-dark");
  position: relative;
  margin-bottom: 15px;
}

.offer-card__top {
  padding: 50px 16px 25px;
  background-repeat: no-repeat;
  background-position-x: 100%;

  @include breakpoint(large) {
    padding: 50px 25px 25px;
  }
}

.offer-card--dark {
  .offer-card__top {
    background-color: color("primary-dark");
    color: color("primary-light");

    background-image: url("../../assets/images/blobs/OfferCardBlobSmallLight.svg");

    @include breakpoint(large) {
      background-image: url("../../assets/images/blobs/OfferCardBlobLargeLight.svg");
    }
  }

  .offer-card__valid-until {
    color: color("placeholder-text");
  }
}

.offer-card--light {
  .offer-card__top {
    background-color: color("primary-light");
    color: color("primary-dark");

    background-image: url("../../assets/images/blobs/OfferCardBlobSmallDark.svg");

    @include breakpoint(large) {
      background-image: url("../../assets/images/blobs/OfferCardBlobLargeDark.svg");
    }
  }

  .offer-card__valid-until {
    color: color("secondary-text");
  }
}

.offer-card__blob {
  position: absolute;
  right: -50px;

  @include breakpoint(medium) {
    right: 0;
  }
}

.offer-card__bubble {
  border: 2px solid color("primary-dark");
  color: color("primary-dark");
  padding: 8px 16px;
  position: absolute;
  background: color("primary-light");
  border-radius: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform: translateY(-50%);
  margin-left: 8px;

  @include breakpoint(medium) {
    margin-left: 16px;
  }
}

.offer-card__title {
  max-width: 85%;
  font-family: $ff-body;

  @include breakpoint(xlarge) {
    max-width: 100%;
    margin-bottom: 16px;
  }
}

.offer-card__bubble-text {
  margin: 0 8px;
}

.offer-card__premium {
  display: flex;
  flex-direction: column;
  margin-bottom: 36px;
}

.offer-card__premium-amount,
.offer-card__premium-suffix {
  display: inline-block;
}

.offer-card__premium-suffix {
  margin-top: -9px;
}

.offer-card__cta,
.offer-card__secondary-cta {
  width: 100%;
  margin-bottom: 15px;
  height: 60px;
}

.offer-card__valid-until {
  color: color("placeholder-text");
  text-align: center;
}

.offer-card__bottom {
  padding: 0 16px 16px;
  background-color: color("secondary-dark");

  @include breakpoint(large) {
    padding: 0px 25px 25px;
  }
}

.offer-card__details-list-item {
  height: 70px;
  padding-top: 25px;
  padding-bottom: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .text-tag {
    white-space: initial;
  }
}

.offer-card__details-list-item--border {
  &:not(:last-child) {
    border-bottom: 1px solid color("placeholder-text");
  }
}
