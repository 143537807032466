@import "scss/main";

.tile {
  align-items: center;
  background: color("secondary-light");
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 16px 20px;

  &--clickable {
    cursor: pointer;
  }
}

.tile__icon {
  height: 24px;
  width: 24px;
}

.tile__content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.tile__title {
  color: color("primary-dark");
}

.tile__body {
  color: color("secondary-text");
}
