@import "scss/main";

.checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  margin-right: 25px;
  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &.focus-visible + .checkbox__label-text {
      outline: color("focus-outline") solid 1px;
    }
  }
}

.checkbox--disabled {
  cursor: none;
  opacity: 0.5;
  pointer-events: none;
}

.checkbox__checkmark {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 16px;
  height: 16px;
  background: $tertiary-bg;

  .checkbox__checkmark--icon {
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  &.checked {
    .checkbox__checkmark--icon {
      opacity: 1;
    }
  }
}
