@import "scss/main";

.custom-select-input {
  position: relative;

  .menu-container {
    width: 100%;
    .menu-wrapper {
      width: 100%;
    }
  }

  .text-input_input {
    cursor: pointer;
  }

  .custom-select-input__error-text {
    color: color("error-dark");
    margin: 5px 0;
  }

  .custom-select__item {
    padding: 0 24px;
    height: 0;
    opacity: 0;
    transition: height 0.25s, opacity 0.25s, background-color 0.25s;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;

    &:hover {
      background-color: color("tertiary-bg");

      &::after {
        position: absolute;
        right: 0;
        content: "";
        height: 1px;
        width: 100%;
        background: color("tertiary-bg");
        top: -1px;
      }
    }

    &.custom-select__item--highlighted {
      background-color: color("tertiary-bg");
    }
    &.custom-select__item--active {
      color: color("cta");
    }
  }

  .custom-select__border--bottom {
    position: relative;

    &::after {
      position: absolute;
      right: 0;
      content: "";
      background: color("tertiary-bg");
      height: 1px;
      width: calc(100% - 24px);
      bottom: 0;
    }

    &:last-of-type {
      &::after {
        background: none;
        height: none;
      }
    }
  }
}

.native-select-input__wrapper {
  position: relative;
  min-width: 95px;
}

.native-select-input {
  width: 100%;
  height: 61px;
  background: color("secondary-dark");
  color: color("secondary-text");
  padding: 14px 40px 14px 8px;
  border: none;
  margin-bottom: 8px;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  @include breakpoint(large) {
    padding: 14px 48px 14px 16px;
    height: 71px;
  }
}

.native-select-input--empty {
  color: color("placeholder-text");
}

.native-select-input__icon {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);

  @include breakpoint(large) {
    right: 24px;
  }
}

.custom-select__prompt,
.native-select__prompt {
  margin: 24px 0 8px;
}

.custom-select-input--disabled {
  pointer-events: none;
  cursor: none;
}
