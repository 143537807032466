@use "scss/main" as *;

.text-link {
  background: transparent;
  border: none;
  display: inline-block;
  margin: 0;
  padding: 0;
}

.text-link--disabled {
  cursor: none;
  opacity: 0.5;
  pointer-events: none;
}

.text-link__text {
  color: color("cta");
  transition: color 0.2s;
  cursor: pointer;

  &:hover {
    color: color("cta-pressed");
  }
}
