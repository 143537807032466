// Ascending
// Used to evaluate Sass maps like our grid breakpoints.
@mixin _assert-ascending($map, $map-name) {
  $prev-key: null;
  $prev-num: null;

  @each $key, $num in $map {
    @if $prev-num==null {
      // Do nothing
    } @else if not comparable($prev-num, $num) {
      @warn "Potentially invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} whose unit makes it incomparable to #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    } @else if $prev-num>=$num {
      @warn "Invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} which isn't greater than #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    }

    $prev-key: $key;
    $prev-num: $num;
  }
}

// Starts at zero
// Another grid mixin that ensures the min-width of the lowest breakpoint starts at 0.
@mixin _assert-starts-at-zero($map) {
  $values: map-values($map);
  $first-value: nth($values, 1);

  @if $first-value !=0 {
    @warn "First breakpoint in `$grid-breakpoints` must start at 0, but starts at #{$first-value}.";
  }
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);

  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);

  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if $min !=null and $max !=null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max==null {
    @include media-breakpoint-up($lower, $breakpoints) {
      @content;
    }
  } @else if $min==null {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}

// Media between the breakpoint's minimum and maximum widths.
// No minimum for the smallest breakpoint, and no maximum for the largest one.
// Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  $max: breakpoint-max($name, $breakpoints);

  @if $min !=null and $max !=null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max==null {
    @include media-breakpoint-up($name, $breakpoints) {
      @content;
    }
  } @else if $min==null {
    @include media-breakpoint-down($name, $breakpoints) {
      @content;
    }
  }
}

// BOX SHADOW

@mixin box-shadow($shadow...) {
  @if $enable-shadows {
    box-shadow: $shadow;
  }
}

// TRANSITION

@mixin transition($transition...) {
  @if $enable-transitions {
    @if length($transition) ==0 {
      transition: $transition-base;
    } @else {
      transition: $transition;
    }
  }
}

// FORMS

// Forms mixins

// Form control focus state
@mixin form-control-focus() {
  &:focus {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;

    @if $enable-shadows {
      box-shadow: $input-focus-box-shadow;
    }

    // Set the border color to the focused state border color
    // when the input is also hovered.
    &:hover {
      border-color: $input-focus-border-color;
    }
  }
}

@mixin form-validation-state($state, $color) {
  .#{$state}-feedback {
    margin-top: $form-feedback-margin-top;
    font-size: $form-feedback-font-size;
    color: $color;
  }

  .#{$state}-tooltip {
    background-color: rgba($color, 0.8);
  }

  .form-control,
  .custom-select {
    .was-validated &:#{$state},
    &.is-#{$state} {
      border-color: $color;

      @include box-shadow(0 5px 11.5px rgba($color, 0.1));

      &:focus {
        box-shadow: 0 5px 11.5px rgba($color, 0.1), 0 1px 1px 0.1rem rgba($color, 0.2);
      }

      &:hover {
        border-color: $color;
      }
    }
  }

  .form-check-input {
    .was-validated &:#{$state},
    &.is-#{$state} {
      ~ .form-check-label {
        color: $color;
      }
    }
  }

  .custom-control-input {
    .was-validated &:#{$state},
    &.is-#{$state} {
      ~ .custom-control-label {
        color: $color;

        &::before {
          background-color: lighten($color, 20%);
          border-color: lighten($color, 10);
        }
      }

      &:checked {
        ~ .custom-control-label::before {
          @include gradient-bg(lighten($color, 10%));
        }
      }

      &:focus {
        ~ .custom-control-label::before {
          box-shadow: 0 0.313rem 0.719rem rgba($color, 0.1), 0 0.156rem 0.125rem rgba($black, 0.06);
        }
      }
    }
  }

  // Custom file input
  .custom-file-input {
    .was-validated &:#{$state},
    &.is-#{$state} {
      ~ .custom-file-label {
        color: $color;
        border-color: $color;

        &::after {
          background-color: lighten($color, 40);
          border-color: lighten($color, 10);
          color: $color;
        }
      }
    }

    &:focus {
      .was-validated &:#{$state},
      &.is-#{$state} {
        ~ .custom-file-label {
          border-color: $color;
          box-shadow: 0 5px 11.5px rgba($color, 0.1), 0 1px 1px 0.1rem rgba($color, 0.2);
        }
      }
    }

    // Fix default custom input hover color issue on validated
    // custom file inputs.
    &:hover {
      .was-validated &:#{$state},
      &.is-#{$state} {
        ~ .custom-file-label {
          border-color: $color;
        }
      }
    }
  }

  // Custom toggles validation
  .custom-toggle {
    .custom-control-input {
      &:not(:checked) {
        .was-validated &:#{$state},
        &.is-#{$state} {
          ~ .custom-control-label::before {
            background-color: $custom-toggle-invalid-background-color;
          }
        }
      }

      // Valid custom toggle
      .was-validated &:valid,
      &.is-valid {
        ~ .custom-control-label {
          &::before {
            background-color: $custom-toggle-checked-background;
          }
        }
      }

      // Invalid custom toggle
      .was-validated &:invalid,
      &.is-invalid {
        ~ .custom-control-label {
          &::after {
            background-color: $custom-toggle-invalid-knob-background-color;
          }
        }

        &:focus {
          ~ .custom-control-label::before {
            box-shadow: 0 0.313rem 0.719rem rgba($color, 0.1), 0 0.156rem 0.125rem rgba($black, 0.06);
          }
        }
      }
    }
  }
}

@mixin border-radius($radius: $border-radius) {
  @if $enable-rounded {
    border-radius: $radius;
  }
}

@mixin border-top-radius($radius) {
  @if $enable-rounded {
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
  }
}

@mixin border-right-radius($radius) {
  @if $enable-rounded {
    border-top-right-radius: $radius;
    border-bottom-right-radius: $radius;
  }
}

@mixin border-bottom-radius($radius) {
  @if $enable-rounded {
    border-bottom-right-radius: $radius;
    border-bottom-left-radius: $radius;
  }
}

@mixin border-left-radius($radius) {
  @if $enable-rounded {
    border-top-left-radius: $radius;
    border-bottom-left-radius: $radius;
  }
}

// Gradients

@mixin gradient-bg($color) {
  @if $enable-gradients {
    background: $color linear-gradient(180deg, mix($body-bg, $color, 15%), $color) repeat-x;
  } @else {
    background-color: $color;
  }
}

// Horizontal gradient, from left to right
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
@mixin gradient-x($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  background-image: linear-gradient(to right, $start-color $start-percent, $end-color $end-percent);
  background-repeat: repeat-x;
}

// Vertical gradient, from top to bottom
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
@mixin gradient-y($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent);
  background-repeat: repeat-x;
}

@mixin gradient-directional($start-color: #555, $end-color: #333, $deg: 45deg) {
  background-image: linear-gradient($deg, $start-color, $end-color);
  background-repeat: repeat-x;
}

@mixin gradient-x-three-colors($start-color: #00b3ee, $mid-color: #7a43b6, $color-stop: 50%, $end-color: #c3325f) {
  background-image: linear-gradient(to right, $start-color, $mid-color $color-stop, $end-color);
  background-repeat: no-repeat;
}

@mixin gradient-y-three-colors($start-color: #00b3ee, $mid-color: #7a43b6, $color-stop: 50%, $end-color: #c3325f) {
  background-image: linear-gradient($start-color, $mid-color $color-stop, $end-color);
  background-repeat: no-repeat;
}

@mixin gradient-radial($inner-color: #555, $outer-color: #333) {
  background-image: radial-gradient(circle, $inner-color, $outer-color);
  background-repeat: no-repeat;
}

@mixin gradient-striped($color: rgba(255, 255, 255, 0.15), $angle: 45deg) {
  background-image: linear-gradient(
    $angle,
    $color 25%,
    transparent 25%,
    transparent 50%,
    $color 50%,
    $color 75%,
    transparent 75%,
    transparent
  );
}

@mixin dark() {
  @at-root {
    body.dark-theme:not(.light-theme) & {
      @content;
    }

    body:not(.light-theme) & {
      @media screen and (prefers-color-scheme: dark) {
        @content;
      }
    }
  }
}

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg, $display: auto) {
  $src: null;

  $extmods: (
    eot: "?",
    svg: "#" + str-replace($name, " ", "_"),
  );

  $formats: (
    otf: "opentype",
    ttf: "truetype",
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    font-display: $display;
    src: local(quote($name)), $src;
  }
}
