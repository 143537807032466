@import "scss/main";

.offer-card__fine-print {
  &.text-tag {
    @include breakpoint(medium down) {
      margin-bottom: 15px;
    }
  }
}

.ad-offer-card__fine-print-button {
  display: inline-block;
  text-transform: none;
  font-weight: normal;

  &.text-dark {
    padding: 0;
  }
}

.offer-card__ad-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: flex-start;
  align-items: center;
  cursor: pointer;
  margin-top: 16px;

  @include breakpoint(medium) {
    align-self: center;
  }
}

.offer-card__ad-info-text {
  color: color("cta");
  margin-left: 3px;
  &:hover,
  &:focus {
    color: color("cta-pressed");
  }
}
