@import "scss/main";

.product-offering-premium-info {
  .product-offering-premium-info__amount {
    margin-bottom: 4px;
  }

  .product-offering-premium-info__amount-label {
    margin-bottom: 16px;
  }

  .product-offering-premium-info__cta {
    margin-bottom: 8px;
  }
}

.term-life-product-offering__premium-label,
.product-offering-premium-info__validity-text {
  color: color("text-secondary");
}

.product-offering-premium-info__amount-label {
  text-transform: uppercase;
}
