@import "scss/main";

.card--marketing {
  align-items: flex-start;
  background-color: color("secondary-light");
  border: 0.5px solid color("primary-dark");
  border-radius: 10px;
  box-shadow: 0px 4px 8px 0px rgba(39, 55, 64, 0.1);
  display: flex;
  flex-direction: column;
  padding: 24px;
  position: relative;

  @include breakpoint(medium) {
    margin: 0 auto;
    width: 400px;
  }

  @include breakpoint(large) {
    margin: 0;
  }

  .card__bubble {
    align-items: center;
    background-color: color("secondary-dark");
    border: 1px solid color("primary-dark");
    border-radius: 80px;
    color: color("primary-dark");
    display: inline-flex;
    gap: 8px;
    padding: 8px 16px;
    position: absolute;
    justify-content: center;
    top: -19px;
  }

  .card__icon {
    position: absolute;
    right: 24px;
    top: 24px;

    @include breakpoint(large) {
      max-height: 64px;
      width: 64px;
    }
  }

  .card__header,
  .card__body {
    padding-right: 40px;

    @include breakpoint(large) {
      padding-right: 72px;
    }
  }

  .card__header {
    margin-bottom: 6px;
  }

  .card__body {
    margin-bottom: 16px;
  }

  .card__cta,
  .card__cta-btn {
    width: 100%;

    @include breakpoint(medium) {
      min-width: 240px;
      width: auto;
    }
  }
}
