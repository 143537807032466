@import "scss/main";

@keyframes bg-slide {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0%);
  }
}

.trivia-question__container {
  height: 100%;
  overflow-y: scroll;
  padding: 96px 20px 0;
  position: relative;

  > :not(.trivia-question__background) {
    z-index: 50;
  }

  > :not(.trivia-question__background):not(.trivia-banner__container) {
    position: relative;
  }
}

.trivia-question__background {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 0;
}

.trivia-question__background--color {
  animation: linear 1 forwards bg-slide;
  background-color: color("primary-dark");
}

.trivia-question__background--image {
  background-color: rgba(color("primary-dark"), 0.95);
  background-image: url("../../assets/images/trivia/constellation-white.svg");
  background-position: 49% -198px;
  background-repeat: no-repeat;
}

.trivia-question__number-indicator {
  background-color: color("primary-light");
  border-radius: 24px;
  color: color("primary-dark");
  font-family: $ff-ogg;
  font-size: 24px;
  height: 48px;
  letter-spacing: -0.5px;
  line-height: 48px;
  margin: 0 auto 16px;
  text-align: center;
  width: 48px;
}

.trivia-question__question-text {
  color: color("primary-light");
  font-family: $ff-body;
  font-size: 24px;
  letter-spacing: -0.5px;
  line-height: 1.38;
  margin-bottom: 36px;
  margin-left: -2px;
  margin-right: -2px;
  text-align: center;
}

.trivia-question__answer-list {
  margin: 0 auto;
}

.trivia-question__answer-list-item {
  list-style-type: none;
}

.trivia-question__answer-list-item-button {
  background-color: transparent;
  border: 2px solid color("primary-light");
  border-radius: 60px;
  color: color("primary-light");
  cursor: pointer;
  font-size: 16px;
  font-weight: $font-weight-bold;
  letter-spacing: -0.5px;
  line-height: 1.6;
  margin-bottom: 8px;
  outline: none;
  padding: 14px;
  width: 100%;

  &:focus,
  &:hover {
    background-color: color("btn-hover");
    outline: none;
  }

  .trivia-question__answer-list-item--correct & {
    background-color: color("success-light");
    border-color: color("success-light");
  }

  .trivia-question__answer-list-item--incorrect & {
    background-color: color("error-dark");
    border-color: color("error-dark");
  }

  .trivia-question__answer-list--answer-selected & {
    pointer-events: none;
  }
}
