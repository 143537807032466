@import "scss/main";

.quote-estimate {
  padding: 24px;
  color: $primary-light;
  background-color: $primary-dark;
  gap: 20px;

  @include breakpoint(medium) {
    padding: 32px 24px;
  }

  .quote-estimate__content-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .quote-estimate__btn {
    padding: 20px;
    width: 100%;
    max-width: 350px;
  }

  .quote-estimate__cost {
    transition: opacity 0.2s;
  }

  .quote-estimate__time {
    display: flex;
    align-items: center;
    color: color("secondary-light");
    justify-content: center;
  }

  .quote-estimate__time-icon {
    margin-right: 10px;
  }
}
