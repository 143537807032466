.wysh-category-title__wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .wysh-category-title__title {
    margin-bottom: 0;
    margin-right: 8px;
  }

  .wysh-category-title__flair {
    display: block;
    width: 24px;
    height: 6px;
  }
}
