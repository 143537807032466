@import "scss/main";

// .unitized-input {}

// .unitized-input--error {

// }

.unitized-input__error-text {
  color: color("error-dark");
  margin: 5px 0;
}
