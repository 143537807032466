@use "scss/main" as *;
@use "components/ProgressBar/ProgressBar.scss" as *;

// adjust term-life layout padding
.dynamic-form__container {
  .tl-product-offering-layout {
    padding-top: 0;
  }
}

// adjust accidental-death layout padding
.navigation-section:has(~ .flow-container
    :is(.dynamic-form--about-accidental-death, .dynamic-form--quote-review-accidental-death)) {
  padding: 0;
  padding-bottom: $progress-bar-height;
}
.dynamic-form--quote-review-term-life {
  .navigation-section {
    padding-top: 0;
  }
}

// hide leading-navigation if backnav sibling exists
.header__portal:has(.leading-navigation-button ~ .backnav__container) {
  .leading-navigation-button {
    display: none;
  }
}
