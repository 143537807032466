@import "scss/main";

@import "scss/settings/settings";
@import "scss/utilities/normalize";
@import "scss/utilities/colors";
@import "scss/utilities/display";
@import "scss/utilities/fonts";
@import "scss/utilities/typography";
@import "scss/plugins/plugins";

// Components
@import "scss/components";
html {
  @include normalize();
  height: 100%;
  box-sizing: border-box;

  body {
    @include typography();

    margin: 0;
    color: color("primary-dark");
    font-family: $ff-body;
    background: color("primary-light");
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    .App {
      display: flex;
      min-height: 100vh;
      flex-direction: column;

      .App-content__container {
        flex: 1;
      }
    }
  }

  a {
    text-decoration: none;
  }
}

.pointer {
  cursor: pointer;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex--center {
  display: flex;
  align-items: center;
}

.flex--justify-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex--center-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-column--center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-column--start {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.flex-column--end {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.flex--baseline {
  display: flex;
  align-items: baseline;
}

.flex-stretch {
  align-items: stretch;
}

.full-width {
  width: 100%;
}

.full-stretch {
  width: 100%;
  height: 100%;
}

.margin-auto {
  margin-left: auto;
  margin-right: auto;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-50 {
  margin-top: 50px;
}

.p-0 {
  padding: 0;
}

.uppercase {
  text-transform: uppercase;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.02);
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-transform-none {
  text-transform: none !important;
}
