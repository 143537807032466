// REGULAR WEIGHT - 400
@include font-face(
  "Henderson Sans",
  "~assets/fonts/HendersonSans/HendersonSans-Normal",
  400,
  normal,
  woff2 woff otf
);

// SEMI-BOLD WEIGHT - 600
@include font-face(
  "Henderson Sans",
  "~assets/fonts/HendersonSans/HendersonSans-SemiBold",
  600,
  normal,
  otf
);

// BOLD WEIGHT - BOLD
@include font-face(
  "Henderson Sans",
  "~assets/fonts/HendersonSans/HendersonSans-Bold",
  bold,
  normal,
  woff2 woff otf
);
