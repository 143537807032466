@import "scss/main";

.policy-updates-banner__container {
  width: 100%;
  background-color: color("primary");
  color: color("primary-light");
  padding: 8px 8px 8px 15px;
  @include fadeIn();

  .policy-updates-banner__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    max-width: $max-column-width;

    @include breakpoint(large) {
      max-width: 860px;
    }
  }

  .policy-updates-banner__message {
    flex: 2;
  }

  .updates-banner__button {
    height: 35px;

    @include breakpoint(xlarge) {
      height: 40px;
    }

    &:hover {
      background: color("black");
    }
  }
}
