.currency-input {
  &.text-input {
    &.text-input--disable-floating-label {
      &.text-input--has-label {
        input {
          padding-left: 40px;
        }
      }
    }
  }

  .icon-container {
    top: 49%;
    width: auto;

    svg {
      max-height: 18px;
    }
  }
}
