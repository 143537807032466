@import "scss/main";

.slider-input__container {
  width: 100%;
  max-width: 100%;

  @include breakpoint(medium) {
    max-width: $max-column-width;
  }

  .slider-input__wrapper {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin-top: 15px;

    .slider-input__slider {
      position: relative;
      width: 100%;
      height: 4px;
      margin-top: 19px;
      margin-bottom: 19px;
      border-radius: 4px;
      background: color("secondary-dark");
      cursor: ew-resize;
      transition: background 0.1s;

      .slider-input__bar {
        position: absolute;
        width: 0;
        top: -3px;
        left: 0;
        height: 10px;
        background: color("cta");
        padding: 3px 0;
        border-radius: 10px;
        transition: background 0.1s, width 0.05s ease-out;
      }

      .slider-input__thumb {
        position: absolute;
        top: -19px;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        background-image: url("../../assets/icons/Slider/Slider.svg");
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        transform: translateX(-50%);
        touch-action: none;
        transition: left 0.05s ease-out;

        &:hover {
          cursor: ew-resize;
        }
      }

      .slider-input {
        -webkit-appearance: none;
        appearance: none;
        outline: none;
        width: 100%;
        max-width: 100%;
        cursor: ew-resize;
        position: absolute;
        background: transparent;
        visibility: hidden;
      }
    }

    .slider-input__datalist {
      display: flex;
      justify-content: space-between;
      max-width: 100%;
      width: 100%;
      margin-top: 22px;

      .datalist-option {
        transition: color 0.1s;
        transform: translateX(-25%);

        &:first-child,
        &:last-child {
          transform: none;
        }
      }
    }

    &.inactive {
      .slider-input__bar {
        padding: 0;
        border-radius: 4px;
        background: color("placeholder-text");
      }

      .slider-input__thumb {
        background-image: url("../../assets/icons/InActiveSlider/InActiveSlider.svg");
      }

      .slider-input__datalist {
        .datalist-option {
          color: color("placeholder-text");
        }
      }
    }
  }
}
