@import "scss/main";

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 2s alternate;
  }
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

// Hide Zendesk launcher to prevent it from covering other elements
// Zendesk does not provide a more stable id than the title attribute
// Zendesk does not provide a way to hide the launcher but plans to in the future:
// https://support.zendesk.com/hc/en-us/community/posts/4409222686618-Unhide-hide-messaging-widget
[title^='Button to launch messaging window'] {
  display: none;
}

// Hide ZVA launcher to prevent it from covering other elements
// ZVA does not provide a more stable id than this
#livesdk__campaign {
  display: none;
}
