@import "scss/main";

$mobile-clip-path: "M0 40.4401V40.4457C0.000461097 47.0146 1.6196 53.2873 5.12605 58.064C14.2428 70.6287 36.2919 72.8809 53.3129 73.7352C57.0093 73.9001 60.4637 73.9996 63.7584 74C63.7729 74 63.7874 74 63.8018 74C63.8679 74 63.934 73.9999 64 73.9999V0.175365C62.7702 0.142734 61.5211 0.115473 60.2506 0.0908773C43.7027 -0.245915 23.5755 -0.235868 11.7001 11.1932C4.28653 18.28 0.000750228 29.7517 0 40.4401Z";
$desktop-clip-path: "M1.9895 110.738C0.917135 82.8563 0.142137 62.7064 12.1397 37.6707C29.6128 1.20873 54.5459 0.586597 68.7024 0.233365C70.6998 0.183527 72.4826 0.139042 73.9997 2.27161e-06C73.9998 -5.59286e-06 73.9996 1.01235e-05 73.9997 2.27161e-06L74 280C73.9999 280 74.0001 280 74 280C25.3295 280 -5.75385 252.45 0.89233 179.92C3.53932 151.033 2.69927 129.192 1.9895 110.738Z";

$medium-drawer-width: 376px;
$large-drawer-width: 500px;

.text-rotated-right {
  writing-mode: tb-rl;
}

.text-rotated-left {
  writing-mode: tb-rl;
  transform: rotate(180deg);
}

.drawer-knob__container {
  position: fixed;
  z-index: 1050;
  top: 88px;
  right: 0;
  width: 64px;
  height: 74px;
  cursor: pointer;
  transition: top 0.3s ease-in-out;



  @include breakpoint(small only) {
    &.drawer-knob__container__immersive-mode {
      top: 8px;
    }

    &.open {
      top: 8px;
    }
  }

  @include breakpoint(medium) {
    top: 90px;
    width: 74px;
    height: 280px;
    transition: right ease-in-out 0.3s;
  }

  &.ping-knob {
    @include bump(1, 0.5s);
  }

  &.slide-in-knob {
    @include slideInLeft(1, 0.5s);
  }

  .drawer-knob__wrapper {
    height: 100%;
    width: 100%;
    padding: 0;
    border: 0;
    background-color: inherit;
    cursor: pointer;
    .drawer-knob__content {
      background-color: color("black");
      color: color("white");
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      user-select: none;
      overflow: hidden;
      clip-path: path($mobile-clip-path);

      @include breakpoint(medium) {
        clip-path: path($desktop-clip-path);
      }

      .drawer-knob__content-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .drawer-knob__content-close {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .drawer-knob__content-close__icon {
          width: 24px;

          @include breakpoint(medium) {
            width: 16px;
            margin-top: -60px;
            margin-bottom: 70px;
          }
        }
      }
    }
  }

  &.open {
    right: 0;

    @include breakpoint(medium) {
      right: $medium-drawer-width;
    }

    @include breakpoint(large) {
      right: $large-drawer-width;
    }
  }
}

.drawer__drawer-container {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1040;

  .drawer-overlay__container {
    position: absolute;
    background-color: transparentize(color("smoke"), 0.3);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .drawer-body__container {
    position: relative;
    height: 100%;

    .drawer-body__wrapper {
      background-color: color("primary-light");
      position: absolute;
      width: 100%;
      max-width: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      right: 0;
      top: 0;
      height: 100%;
      z-index: 1070;

      @include breakpoint("medium") {
        width: $medium-drawer-width;
      }

      @include breakpoint("large") {
        width: $large-drawer-width;
      }
    }
  }
}
