@import "scss/main";

.onboarding-carousel__container {
  margin-bottom: 20px;
}

.onboarding-carousel__wrapper {
  overflow: hidden;
  padding-bottom: 20px;
}

.onboarding-carousel__arrows {
  display: none;

  @include breakpoint(medium) {
    display: flex;
  }
}

.onboarding-carousel__dots {
  text-align: left;
  left: 0%;

  @include breakpoint(medium) {
    left: 55%;
  }

  li {
    button {
      &::before {
        background: black;
        width: 24px;
        height: 3px;
        content: "";
      }
    }
  }
}
