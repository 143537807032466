@import "scss/main";

.two-col-layout {
  display: flex;
  flex-direction: column;
  padding: 24px;

  @include breakpoint(large) {
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px;
  }

  .two-col-layout__wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;

    @include breakpoint(large) {
      flex-direction: row;
    }

    @include breakpoint(large) {
      justify-content: flex-start;
    }

    .two-col-layout__left {
      margin: 0 auto;
      width: 100%;
      max-width: 450px;

      @include breakpoint(medium) {
        max-width: 350px;
      }

      @include breakpoint(large) {
        max-width: 375px;
        margin-right: 100px;
      }

      @include breakpoint(xlarge) {
        max-width: 450px;
      }

      &.image-column {
        margin: 0;
        max-width: 470px;

        @include breakpoint(xlarge) {
          max-width: 500px;
        }
      }

      .two-col-layout__image-wrapper {
        text-align: center;
      }

      .two-col-layout__left--text-wrapper {
        margin-bottom: 25px;
        max-width: 400px;

        .two-col-layout__left--text-title {
          margin-bottom: 24px;
        }
      }
    }

    .two-col-layout__right {
      width: 100%;
      margin: 0 auto;

      @include breakpoint(medium) {
        max-width: 350px;
      }

      @include breakpoint(large) {
        max-width: 400px;
      }

      .two-col-layout__header {
        @include breakpoint(large) {
          margin-bottom: 15px;
        }
      }

      .two-col-layout__header-button {
        margin: 0 0 15px;
        padding: 5px 0;
        text-transform: none;
        width: auto;
      }
    }

    .two-col-layout__subheader {
      font-size: 14px;
      color: color("600");
    }

    .two-col-layout__subheader-el {
      margin-top: 10px;
      margin-bottom: 15px;

      @include breakpoint(medium) {
        margin-top: 25px;
        margin-bottom: 25px;
      }
    }

    .two-col-layout__text {
      font-size: 16px;
    }

    @include breakpoint(medium) {
      .two-col-layout__link {
        display: none;
      }
    }
  }

  .two-col-layout__subheader {
    margin-bottom: 15px;

    @include breakpoint(medium) {
      margin-bottom: 35px;
    }
  }

  .two-col-layout__next {
    margin-left: auto;
  }
}
