@import "scss/main";

.card-container {
  &.card-container__added-wishes {
    padding: 16px;
    width: 132px;
    height: 140px;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: space-between;

    @include breakpoint(medium) {
      width: 140px;
    }

    @include breakpoint(large) {
      width: 150px;
    }

    .card-content__icon-text-wrapper {
      .card-content__icon-wrapper {
        height: 48px;
        width: 48px;
        margin: -8px -8px 0 0;
        overflow: hidden;
        border-radius: 50%;

        .card-content__icon {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }

    .card-content {
      margin: 0;
    }
  }
}
