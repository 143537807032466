@use "scss/main" as *;

.customer-support__call-cta-link {
  color: color("cta");

  .customer-support__tile:focus &,
  .customer-support__tile:hover & {
    color: color("cta-pressed");
  }
}

.customer-support__tile {
  transition: $transition-base;

  &:hover {
    box-shadow: box-shadow(small);
  }
}
