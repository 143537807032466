@import "scss/main";

.customer-support-modal__subheader-inline-cta {
  white-space: nowrap;
}

.customer-support-modal__subheader-subscript {
  margin: 24px auto 0;
  max-width: 408px;
}
