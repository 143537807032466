@import "scss/main";

.onboarding-carousel-card {
  @include breakpoint(medium) {
    margin-bottom: 15px;
  }

  @include breakpoint(large) {
    margin-bottom: 25px;
  }
}

.onboarding-carousel-card__wrapper {
  display: flex;

  @include breakpoint(large) {
    flex-direction: column;
    justify-content: space-between;
  }

  > div {
    @include breakpoint(medium) {
      max-width: 50%;
    }

    @include breakpoint(large) {
      max-width: 100%;
    }
  }
}

.onboarding-carousel-card__content {
  margin-top: 15px;
  margin-bottom: 35px;

  @include breakpoint(medium) {
    margin-top: 25px;
    margin-bottom: 15px;
  }

  @include breakpoint(large) {
    margin-bottom: 50px;
  }
}

.onboarding-carousel-card__text--subtitle {
  color: color("secondary-text");
  margin-top: 8px;

  @include breakpoint(medium) {
    margin-top: 16px;
  }
}

.onboarding-carousel-card__img {
  display: none;
  width: 100%;

  @include breakpoint(medium) {
    display: flex;
    justify-content: center;
  }

  @include breakpoint(large) {
    justify-content: flex-start;
    max-width: 100%;
    margin-bottom: 80px;
  }

  img {
    max-width: 100%;
    max-height: 250px;
    height: 100%;
    object-fit: contain;

    @include breakpoint(large) {
      max-height: 450px;
    }
  }
}
