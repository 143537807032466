@use "scss/main" as *;

// remove purchase-policy__container layout padding
.dynamic-form__container {
  .purchase-policy__container {
    padding-top: 0;
  }
}

// remove payment-confirmation layout padding
.dynamic-form__container {
  .payment-confirmation.container {
    padding-top: 0;
  }
}
