@import "scss/main";

.modal {
  max-width: 90%;
  z-index: 1060;
  margin: 0 auto;

  @include breakpoint(medium) {
    max-width: 100%;
  }

  &.left-align-sm {
    .modal__container {
      @include breakpoint(small only) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      .modal__header {
        @include breakpoint(small only) {
          text-align: left;
        }
      }

      .modal__subheader {
        @include breakpoint(small only) {
          text-align: left;
        }
      }
    }
  }

  .modal__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 0 20px;
    width: 100%;
    text-align: center;
    margin: 0 auto;

    @include breakpoint(medium) {
      padding: 40px 40px 30px 40px;
    }
  }

  &:focus {
    outline: none;
  }

  .modal__icon {
    margin-bottom: 25px;
  }

  .modal__header {
    margin-bottom: 25px;
  }

  .modal__subheader {
    color: color("secondary-text");
    margin-bottom: 10px;
  }

  .modal__subheader-inline-cta {
    color: color("cta");
    cursor: pointer;
  }

  h3 {
    text-align: center;
    margin-bottom: 20px;
  }

  p {
    text-align: center;
    &.text-left {
      text-align: left;
    }
  }

  .modal__btns {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    width: 100%;
    max-width: 350px;

    button {
      width: 100%;
      height: 60px;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.modal__checkbox {
  text-align: left;
  margin-right: 0;
  margin-bottom: 50px;
}
