@import "scss/main";

.textarea-input__container {
  display: block;
  width: 100%;

  .textarea-input__wrapper {
    position: relative;
    width: 100%;
  }

  .textarea-input__label-text {
    position: absolute;
    left: 17px;
    top: 50%;
    transform: translateY(-50%);
    transition: top 0.3s;
    color: color("placeholder-text");
    pointer-events: none;
  }

  textarea {
    width: 100%;
    height: auto;
    padding: 18px;
    outline: 0;
    background: color("secondary-dark");
    font-size: 14px;
    line-height: 1.7;
    border: 1px solid transparent;
    transition: color, border-color, background linear 0.25s;

    &::placeholder {
      color: color("placeholder-text");
    }

    @include breakpoint("medium") {
      font-size: 16px;
    }

    &:focus {
      background: color("tertiary-bg");
    }
  }

  &.textarea-input--error {
    .textarea-input__label-text {
      color: color("error-dark");
    }

    textarea {
      border-color: color("error-dark");

      &:not(:placeholder-shown) {
        color: color("error-dark");
      }
    }
  }

  &.textarea-input--disabled {
    opacity: 0.4;
  }

  .textarea-input__error-text {
    width: 100%;
    color: color("error-dark");
  }
}

.textarea-input__label-wrapper {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 8px;
  margin-bottom: 16px;

  *:nth-child(2) {
    flex: 1;
  }
}

.textarea-input__max-chars {
  flex: 0 1;
  text-align: right;
  color: color("secondary-text");
}
