@use "scss/main" as *;

.contact-service__column {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.contact-service__customer-support {
  gap: 32px;
}

.contact-service__tiles {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
