@import "scss/main";

$menu-padding: 23px;

.menu-container {
  position: absolute;
  width: auto;
  right: 0;
  left: auto;
  z-index: 1000;

  &.align-left {
    left: 0;
  }

  &.fixed {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
    transition: background-color 0.25s ease-out;
  }

  .menu-wrapper {
    background: color("primary-light");
    height: 100%;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.25s;
    width: 100%;
    @include breakpoint(medium) {
      width: 350px;
    }

    @include breakpoint(large) {
      width: 370px;
    }

    .menu__close-wrapper {
      margin-left: 30px;
      margin-top: 10px;
      height: 70px;
      background-color: inherit;
      border: 0;
    }

    .menu-item {
      padding: 0 $menu-padding;
      height: 0;
      opacity: 0;
      transition: height 0.25s, opacity 0.25s, background-color 0.25s;
      user-select: none;
      -webkit-tap-highlight-color: transparent;
      cursor: pointer;

      .menu-item__icon {
        margin-right: 10px;
      }

      &.menu-item--highlighted {
        background-color: color("tertiary-bg");
      }
      &.menu-item--active {
        color: color("cta");
        svg {
          path {
            fill: color("cta");
          }
        }
      }
    }

    .menu__link {
      color: color("primary-dark");

      &.menu-item--active {
        font-weight: 600;
        color: color("cta");
        svg {
          path {
            fill: color("cta");
          }
        }
      }
    }

    @media not all and (pointer: coarse) {
      .menu-item:hover {
        background-color: color("tertiary-bg");

        &::after {
          position: absolute;
          right: 0;
          content: "";
          height: 1px;
          width: 100%;
          background: color("tertiary-bg");
          top: -1px;
        }
      }
    }

    .menu-item__btn {
      display: flex;
      border: 0;
      width: 100%;
      background-color: inherit;
      padding-left: 0;
      color: color("primary-dark");
      cursor: pointer;
    }

    .menu-item__border--bottom {
      position: relative;

      &.menu__link {
        display: inline-block;
        width: 100%;
      }

      &::after {
        position: absolute;
        right: 0;
        content: "";
        background: color("tertiary-bg");
        height: 1px;
        width: calc(100% - #{$menu-padding});
        bottom: 0;
      }

      &:last-of-type {
        &::after {
          background: none;
          height: none;
        }
      }
    }

    .sub-menu--border {
      height: 10px;
      background: color("secondary-dark");
    }
  }

  &.active {
    height: initial;

    &.fixed {
      height: 100%;
      background-color: $modal-overlay-background;
    }

    .menu-wrapper {
      overflow: auto;
      border: 1px solid color("secondary-dark");
      max-height: 420px;

      &.main-site-nav-menu {
        max-height: 100%;
        @include breakpoint(medium) {
          max-height: calc(100vh - 70px);
        }
      }

      .main-menu {
        height: 70px;
        opacity: 1;
        z-index: 60;
      }
    }
  }
}

.sub-menu-container {
  .menu-item__icon-carat {
    .icon-wrapper {
      transition: transform 0.25s;
    }
  }

  .sub-menu--border {
    height: 10px;
    background: color("secondary-dark");
  }

  &.sub-active {
    .sub-menu {
      height: 70px;
      opacity: 1;
    }

    .sub-menu__toggle--text {
      color: color("cta");
      font-weight: 600;
    }

    .menu-item__icon-carat {
      .icon-wrapper {
        transform: rotateX(-180deg);
      }
    }
  }
}
