@use "scss/main" as *;

.dynamic-form-review-layout {
  margin: auto;
  padding: 0 24px 36px;

  @include breakpoint(medium) {
    padding: 0 0 48px;
  }

  .dynamic-form-review-layout__header {
    margin-bottom: 24px;
  }

  .dynamic-form-review-layout__info {
    margin-bottom: 36px;
  }

  &:not(.dynamic-form-review-layout--insurance-profile-review-screen) {
    .form-item--input-checkbox {
      margin-bottom: 0;
      margin-right: 0;
    }
  }
}

.dynamic-form-review-layout--two-col {
  @include breakpoint(large) {
    column-gap: 60px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .dynamic-form-review-layout__header {
    @include breakpoint(large) {
      grid-column: span 2;
    }
  }
}

.dynamic-form-review-layout--aura-review {
  margin: 0 auto;
  max-width: $max-column-width;
}

.dynamic-form-review-layout--insurance-profile-review-screen {
  max-width: $max-column-width;

  @include breakpoint(large) {
    margin-left: 50%;
    transform: translateX(-50%);
    max-width: 860px;
    width: 100%;
  }

  .dynamic-form-review-layout__form-items {
    @include breakpoint(large) {
      margin-top: 12px;
    }
  }
}

.dynamic-form-review-layout-header {
  .dynamic-form-review-layout-header__title {
    margin-bottom: 8px;
  }
}

.dynamic-form-review-layout-header__subtitle {
  color: color("secondary-text");
}

.dynamic-form-review-layout__form-items {
  display: flex;
  flex-direction: column;
}
