@import "scss/main";

.truncate-text_wrapper {
  text-align: left;

  .text-tag {
    display: inline;
  }

  .truncate-text__toggle-text-wrapper {
    background-color: inherit;
    border: 0;
  }

  .truncate-text__toggle-text {
    color: color("cta");
    cursor: pointer;
  }
}
