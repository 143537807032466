@import "scss/main";

.binary-select-input {
  display: flex;
  justify-content: center;

  &.input-filled {
    .binary-select-input__button {
      border-color: transparent;
    }
  }

  &.error {
    .binary-select-input__button {
      border: 1px solid color("error-dark");
      color: color("error-dark");
    }
  }
}

.binary-select-input--disabled {
  cursor: none;
  opacity: 0.5;
  pointer-events: none;
}

.binary-select-input__button {
  min-width: 50%;
  width: 100%;
  height: 61px;
  text-align: center;
  padding: 16px;
  background-color: color("secondary-dark");
  color: color("secondary-text");
  transition: background-color 0.25s, color 0.25s;
  border: 0 solid color("placeholder-text");
  border-right-width: 0.5px;
  cursor: pointer;

  @include breakpoint(large) {
    height: 71px;
  }

  &:last-of-type {
    border-left-width: 0.5px;
    border-right-width: 0;
  }

  &:hover {
    background-color: color("tertiary-bg");
  }

  &.selected {
    background-color: color("primary");
    color: color("primary-light");
  }

  &:disabled {
    pointer-events: none;
  }
}
