@import "scss/main";

$trivia-container-z-index: 1060;

.trivia__container {
  background-color: color("primary");
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: $trivia-container-z-index;

  @include breakpoint(medium) {
    background-image: url("../../../assets/images/trivia/wyshing-star.svg");
    background-position: top 29px right -80px;
    background-repeat: no-repeat;
  }

  @include breakpoint(large) {
    background-image: url("../../../assets/images/trivia/camping.svg"),
      url("../../../assets/images/trivia/wyshing-star.svg");
    /* 259px = offset of star from center, 204px = width of star */
    background-position: bottom, top 34px right calc((50vw) - (259px + 204px));
  }
}

.trivia__viewport {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;

  @include breakpoint(medium) {
    border: 3px solid color("primary-dark");
    border-radius: 28px;
    height: 692px;
    /* Solves Safari misbehaving with overflow + border-radius */
    mask-image: radial-gradient(white, black);
    margin: 0 auto;
    transform: translateY(-50%);
    top: 50%;
    width: 320px;
  }
}
