@import "scss/main";

.accordion-panel {
  padding: 25px 0;
  border-bottom: 1px solid color("placeholder-text");
}

.accordion-panel__btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: inherit;
  width: 100%;
  border: 0;
  text-align: left;
  cursor: pointer;
  padding-left: 0;
}

.accordion-panel__question {
  max-width: 85%;
}

.accordion-panel__content {
  height: 100%;
  max-height: 0;
  transition: max-height 0.2s, padding 0.2s;
  overflow: hidden;

  .text-tag {
    opacity: 0;
    max-height: 0;
    transition: max-height 0.4s, opacity 0.3s;
  }
}

.accordion-panel--expanded {
  .accordion-panel__content {
    max-height: 1000px;
    padding-top: 25px;
    transition: max-height 0.4s, padding 0.3s;

    .text-tag {
      max-height: 1000px;
      opacity: 1;
    }
  }
}

.accordion-panel__carat {
  transition: transform 0.25s;
}

.accordion-panel__carat--expanded {
  transform: rotateX(180deg);
}
