@use "scss/foundations/colors";

$colors-map: map-merge(colors.$semantic-colors, $colors);

.fillable {
  transition: fill linear 0.2s;
}

@each $name, $hex in $colors-map {
  .color-#{$name} {
    color: $hex;

    &.desaturate {
      color: lighten(desaturate($hex, 40), 20);
    }
  }

  .bgcolor-#{$name} {
    background-color: $hex;

    &.desaturate {
      background-color: lighten(desaturate($hex, 40), 20);
    }
  }

  .fill-#{$name} {
    &\! {
      path {
        fill: $hex;
      }
    }
    &.fillable,
    .fillable {
      fill: $hex;
    }
  }

  .stroke-#{$name} {
    &\! {
      path {
        stroke: $hex;
      }
    }
    &.stroke,
    .stroke {
      stroke: $hex;
    }
  }

  .fill-#{$name}_dominant {
    &.fillable.dominant-fill,
    .fillable.dominant-fill {
      fill: $hex;
    }
  }

  .fill-#{$name}_accent {
    &.fillable.accent-fill,
    .fillable.accent-fill {
      fill: $hex;
    }
  }

  @include each-breakpoint(false) {
    .color-#{$name + $breakpoint-separator + $-zf-size} {
      color: $hex;
    }

    .bgcolor-#{$name + $breakpoint-separator + $-zf-size} {
      background-color: $hex;
    }

    .fill-#{$name + $breakpoint-separator + $-zf-size} {
      &.fillable,
      .fillable {
        fill: $hex;
      }
    }

    .fill-#{$name}_dominant#{$breakpoint-separator + $-zf-size} {
      &.fillable.dominant-fill,
      .fillable.dominant-fill {
        fill: $hex;
      }
    }

    .fill-#{$name}_accent#{$breakpoint-separator + $-zf-size} {
      &.fillable.accent-fill,
      .fillable.accent-fill {
        fill: $hex;
      }
    }
  }
}
