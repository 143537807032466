.card-container {
  border-radius: 20px;
  padding: 36px 24px;
  position: relative;
  overflow: hidden;
  max-width: 600px;

  .card-content__background {
    position: absolute;
    top: 0;
    right: 0;
  }

  .card-content {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;

    @include breakpoint(large) {
      max-width: 100%;
    }

    .card-content__action {
      width: 100%;
    }
  }
}
