@import "scss/main";

.phone-number {
  display: flex;

  .phone-number__select {
    margin-right: 7px;
  }

  .phone-number__input {
    .text-input_input {
      padding-left: 13px;
      padding-right: 13px;

      @include breakpoint(medium) {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }
}
