@import "scss/main";

.account-customer-support__container {
  display: flex;
  flex-direction: column;
  margin: 0 16px;

  @include breakpoint(768px) {
    align-items: center;
    margin: 48px 0;
  }

  @include breakpoint(large) {
    flex-direction: row;
    gap: 60px;
    justify-content: center;
    margin: 96px 0;
  }
}

.account-customer-support__column {
  display: flex;
  flex-direction: column;

  @include breakpoint(768px) {
    max-width: 400px;
  }

  &.account-customer-support__content {
    order: 2;
  }

  &.account-customer-support__image-container {
    order: 1;

    @include breakpoint(767px down) {
      display: none;
    }

    @include breakpoint(768px) {
      margin-bottom: 24px;
      padding: 20px 0;
    }

    @include breakpoint(large) {
      margin-bottom: 0;
      order: 2;
    }
  }
}

.account-customer-support__header {
  margin-bottom: 20px;

  @include breakpoint(768px) {
    margin-bottom: 28px;
  }
}

.account-customer-support__tiles {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
