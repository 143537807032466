@import "scss/main";

.header__help {
  border: 0;
  background-color: inherit;
  height: 40px;
  padding: 0 8px;

  @include breakpoint(large) {
    padding: 0 16px;
  }
}

.header__help-icon {
  margin-right: 0;

  path {
    fill: color("primary-dark");

    .header__help:active &,
    .header__help:hover & {
      fill: color("cta");
    }
  }

  @include breakpoint(large) {
    margin-right: 8px;
  }
}

.header__help-text {
  color: color("primary-dark");
  display: none;

  .header__help:active &,
  .header__help:hover & {
    color: color("cta");
  }

  @include breakpoint(large) {
    display: block;
  }
}
