@import "scss/main";

.wysh-build-lite {
  .scrolling-tabs__container {
    .scrolling-tabs__content-container {
      padding-bottom: 80px;

      @include breakpoint(large) {
        padding-bottom: 0;
      }
    }
  }

  .wysh-builder-lite__cta--message-wrapper {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    font-weight: 600;
    color: color("smoke");
  }

  .wysh-builder-lite__list-cta--small-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: color("primary-light");
    padding: 16px 24px;
    border-top: 1px solid color("tertiary-bg");
    z-index: 40;

    .wysh-builder-lite__list-cta--small {
      align-self: center;
      max-width: 450px;
    }

    @include breakpoint(large) {
      position: static;
      border-top: none;
      padding: 0;
    }
  }
}
