@import "scss/main";

.flow-container {
  position: relative;
  z-index: 90;

  .flow__wrapper {
    @include fadeIn();
  }
}

.dynamic-form-container {
  .fulfillment-outages__container {
    padding-top: 0;
  }

  .not-found__container {
    padding-top: 0;
  }
}
