@import "scss/main";

.btn {
  padding: 16px;
  line-height: 130%;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  transition: background-color 0.2s, border-color 0.2s;
  cursor: pointer;

  path {
    transition: fill 0.2s;
  }

  .text-tag {
    transition: color 0.2s;
  }

  &.dark {
    background: transparent;
    color: color("primary-dark");

    path {
      fill: color("primary-dark");
    }

    &:hover,
    &:focus {
      color: color("secondary-text");

      path {
        fill: color("secondary-text");
        stroke: color("secondary-text");
      }
    }

    &.disabled {
      color: color("btn-inactive-dark");

      path {
        fill: color("btn-inactive-dark");
      }
    }
  }

  &.primary {
    background: color("cta");
    color: color("primary-light");

    path {
      fill: color("primary-light");
    }

    &:hover,
    &:focus {
      background: color("cta-pressed");
    }

    &.disabled {
      background: color("btn-inactive-light");
    }
  }

  &.secondary {
    background: color("primary-dark");
    color: color("primary-light");

    path {
      fill: color("primary-light");
    }

    &:hover,
    &:focus {
      background: color("black");
    }

    &.disabled {
      background: color("btn-inactive-dark");
    }
  }

  &.text-dark {
    background: transparent;
    color: color("cta");
    padding: 15px 9px;

    @include breakpoint(medium) {
      padding: 16px;
    }

    path {
      fill: color("cta");
    }

    &:hover,
    &:focus {
      .text-tag {
        color: color("cta-pressed");
      }

      border-color: color("cta-pressed");

      path {
        fill: color("cta-pressed");
      }
    }

    &.disabled {
      color: color("btn-inactive-dark");

      path {
        fill: color("btn-inactive-dark");
      }
    }
  }

  &.text-light {
    background: transparent;
    color: color("primary-light");
    padding: 15px 9px;

    @include breakpoint(medium) {
      padding: 16px;
    }

    path {
      fill: color("primary-light");
    }

    &.disabled {
      color: color("btn-inactive-light");

      path {
        fill: color("btn-inactive-light");
      }
    }
  }

  &.text-light-border {
    background: transparent;
    color: color("primary-light");
    border: 1.5px solid color("primary-light");
    padding: 15px 9px;

    @include breakpoint(medium) {
      padding: 16px;
    }

    path {
      fill: color("primary-light");
    }

    &.disabled {
      color: color("btn-inactive-light");
      border-color: rgba(color("btn-inactive-light"), 0.5);
      opacity: 0.5;

      path {
        fill: color("btn-inactive-light");
      }
    }
  }

  &.text-dark-border {
    background: transparent;
    color: color("cta");
    border: 1.5px solid color("cta");
    padding: 15px 9px;

    @include breakpoint(medium) {
      padding: 16px;
    }

    path {
      fill: color("cta");
    }

    &:hover,
    &:focus {
      .text-tag {
        color: color("cta-pressed");
      }
      border-color: color("cta-pressed");

      path {
        fill: color("cta-pressed");
      }
    }

    &.disabled {
      color: color("btn-inactive-light");
      border: 1.5px solid color("btn-inactive-light");

      path {
        fill: color("btn-inactive-light");
      }
    }
  }

  &.text-dark-no-border {
    background: transparent;
    color: color("cta");
    padding: 15px 9px;

    @include breakpoint(medium) {
      padding: 16px;
    }

    path {
      fill: color("cta");
    }

    &:hover,
    &:focus {
      .text-tag {
        color: color("cta-pressed");
      }
      border-color: color("cta-pressed");

      path {
        fill: color("cta-pressed");
      }
    }

    &.disabled {
      color: color("btn-inactive-light");
      border: 1.5px solid color("btn-inactive-light");

      path {
        fill: color("btn-inactive-light");
      }
    }
  }

  &.text-error-dark {
    background: transparent;
    color: color("error-dark");
    padding: 15px 9px;

    @include breakpoint(medium) {
      padding: 16px;
    }

    &:hover,
    &:focus {
      background: transparent;

      .text-tag {
        color: color("error-dark");
      }
    }
  }

  .icon-container {
    width: auto;
    max-width: 32px;

    .svg-wrapper {
      display: flex;
      align-items: center;

      svg {
        width: 16px;
        height: 16px;

        @include breakpoint(medium) {
          width: 20px;
          height: 20px;
        }
      }
    }

    &.left-icon {
      margin-right: 11px;
    }

    &.right-icon {
      margin-left: 11px;
    }
  }

  &.btn--sm {
    padding: 12px;
  }

  &.btn--is-rounded {
    border-radius: $border-radius-xs;
  }

  &.loading {
    position: relative;

    .loader-container {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    .icon-container {
      visibility: hidden;
    }

    .text-tag {
      visibility: hidden;
    }
  }

  &.disabled {
    pointer-events: none;
  }
}
