@use "scss/main" as *;

.status-label {
  padding: 4px 8px;
  border-radius: 4px;
  color: color("primary-dark");
  text-transform: uppercase;
  text-align: center;
  background-color: color("background-secondary");
}

.status-label--mamey {
  color: color("primary-light");
  background-color: color("primary");
}
