@import "scss/main";

.backnav__container {
  display: flex;
}

.backnav {
  color: $cta;
  align-self: flex-start;
  display: inline-flex;
  cursor: pointer;

  margin: 0 0 24px;

  @include breakpoint(medium) {
    margin: 0 0 48px;
  }

  .backnav__content-wrapper {
    display: inline-flex;
    align-items: center;
  }

  &.backnav--btn {
    border: 0;
    background-color: inherit;
  }

  .backnav__text {
    text-transform: uppercase;
    color: $cta;
    margin-left: 11px;
    transition: color 0.2s;
  }

  @include breakpoint(medium) {
    .backnav__text {
      display: block;
    }
  }

  .icon-container {
    width: 20px;

    svg {
      stroke: $cta;
    }
  }

  a {
    display: inline-block;
  }

  &:hover .backnav__text {
    color: color("cta-pressed");
  }
}

.backnav--self-positioned {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;

  @include breakpoint(large) {
    padding: 0px 48px;

    .backnav {
      margin-bottom: 10px;
    }
  }
}

.backnav--portal {
  letter-spacing: 0;
  margin: 0;

  &.backnav--portal {
    padding: 16px 8px;

    @include breakpoint(large) {
      padding: 16px;
    }
  }

  .backnav__anchor {
    line-height: 0.65;
  }

  .icon-container,
  .icon-container .svg-wrapper svg {
    width: 24px;
  }

  .icon-container .svg-wrapper svg,
  .icon-container .svg-wrapper svg path {
    fill: color("primary-dark");
    stroke: color("primary-dark");
  }

  .backnav__text {
    color: color("primary-dark");
    display: none;
    margin-left: 8px;
    text-transform: capitalize;

    @include breakpoint(large) {
      display: block;
      margin-top: 1px; /* Evens things up with the icon */
    }
  }

  &.backnav--portal:hover {
    .icon-container .svg-wrapper svg,
    .icon-container .svg-wrapper svg path {
      fill: color("secondary-text");
      stroke: color("secondary-text");
    }

    .backnav__text {
      color: color("secondary-text");
    }
  }
}
