@import "scss/main";

.flow__question {
  margin-bottom: 35px;
}

.flow__justification {
  color: color("secondary-text");
}

.flow__description {
  color: color("secondary-text");
  margin-bottom: 10px;
}

.flow__justification-prompt {
  text-transform: uppercase;
}

.flow__field-title_text {
  border-bottom: 1px solid color("tertiary-bg");
  margin-top: 36px;
  margin-bottom: 8px;
}

.flow__field-disclosure_text {
  margin-top: 8px;
  margin-bottom: 8px;
  color: color("ashes");
}

.flow__inputs {
  margin-bottom: 60px;

  &.disabled {
    pointer-events: none;
    opacity: 0.4;
    transition: $transition-fade;
  }

  .multiple-choice-input-container {
    padding-left: 0;
    padding-right: 0;
  }
  .flow__field-container:not(:first-child) {
    .binary-select-input {
      margin-bottom: 8px;
    }
  }
}

.flow__submit-button {
  width: 100%;
  margin-top: 16px;

  @include breakpoint(medium) {
    margin-top: 24px;
    padding: 20px;
  }

  @include breakpoint(large) {
    padding: 20px;
  }

  @include fadeIn(1, 0.5s, 0.25s);
}
