@import "scss/main";

.flow-review-field-answer {
  align-items: center;
  display: inline-flex;
}

.flow-review-field-answer--boolean {
  &:not(:last-child) {
    margin-right: 12px;
  }
}

.flow-review-field-answer--list {
  display: flex;
}

.flow-review-field-answer--numeric,
.flow-review-field-answer--selected,
.flow-review-field-answer--unitized {
  font-weight: 700;
}

.flow-review-field-answer__icon {
  flex-shrink: 0;
  margin-right: 2px;

  path {
    fill: color("ashes");

    .flow-review-field-answer--selected & {
      fill: color("primary-dark");
    }
  }
}

.flow-review-field-answer__text {
  color: color("secondary-text");
  line-height: 24px;

  .flow-review-field-answer--selected & {
    color: color("primary-dark");
  }
}
