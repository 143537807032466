@import "scss/main";

.payment-card-input__container {
  background: color("secondary-dark");

  .payment-card-input__wrapper {
    .payment-card-input__card-row {
      display: flex;
      align-items: center;

      .payment-card-input__card-row-item {
        position: relative;
        height: 71px;
        flex: 1;

        &.payment-card-input__card-number-wrapper {
          .payment-card-input__label-wrapper {
            .payment-card-input__label {
              left: 48px;
            }
          }
        }

        .payment-card-input__label-wrapper {
          .payment-card-input__label {
            position: absolute;
            left: 17px;
            top: 20%;
            transform: translateY(-50%);
            transition: top 0.3s;
            color: color("secondary-text");
            pointer-events: none;
            opacity: 0;
          }
        }

        .payment-card-input {
          padding: 14px 17px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .text-input_input {
            padding: 0;
          }
        }

        &.payment-card-input__card-row-item--active {
          .payment-card-input__label-wrapper {
            .payment-card-input__label {
              top: 20%;
              opacity: 1;
            }
          }
        }


      }
    }
  }
}