@import "scss/main";

.unlink-bank__wyshes-list {
  .wysh-list__item {
    color: color("primary-dark");
  }

  a {
    &:last-of-type {
      .divider {
        display: none;
      }
    }
  }
}
