@import "scss/main";

.blob-list__container {
  counter-reset: blob-list__item;
  list-style-type: none;
  text-align: left;

  .blob-list__item:not(:last-child) {
    margin-bottom: 24px;
  }
}

.blob-list__item {
  color: color("text-primary");
  counter-increment: blob-list__item;

  &::before {
    align-items: center;
    content: counter(blob-list__item);
    background-size: 40px 40px;
    display: inline-flex;
    font-size: 14px;
    font-weight: 600;
    height: 40px;
    justify-content: center;
    margin-right: 16px;
    width: 40px;
  }

  &:nth-child(3n + 1) {
    &:before {
      background-image: url("../../../assets/images/blobs/Blob1_unactive.svg");
    }
  }

  &:nth-child(3n + 2) {
    &:before {
      background-image: url("../../../assets/images/blobs/Blob2_unactive.svg");
    }
  }

  &:nth-child(3n + 3) {
    &:before {
      background-image: url("../../../assets/images/blobs/Blob3_unactive.svg");
    }
  }
}
