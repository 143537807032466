@import "scss/main";

.ad-offer-legalese__container {
  margin-top: 16px;
}

.ad-offer-legalese__text {
  color: color("secondary-text");
  @include breakpoint(medium) {
    text-align: center;
  }
}

.ad-offer-legalese__link {
  display: inline-block;
  text-transform: none;
  font-weight: normal;

  &.text-dark {
    padding: 0;
  }
}
