@use "scss/main" as *;

@include body-typography("-tag__text");
@include header-typography-ver2("-tag__text");
@include body-typography-ver2("-tag__text");

.text-tag {
  white-space: pre-line;
  margin: 0;
}
