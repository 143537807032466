@import "scss/main";

.transfer-fund-success-modal,
.modal {
  .modal-wrapper {
    &.status-type {
      max-width: 228px;
    }
  }
}

.modal.auto-deposit-type {
  .modal__container {
    padding: 48px;

    @include breakpoint(large) {
      padding: 44px;
    }
  }
  &.modal-wrapper {
    &.status-type {
      max-width: 256px;
    }
  }
}
