@import "scss/main";

.ReactModal__Body--open {
  overflow: hidden;
}

.modal-overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1060;
  background-color: $modal-overlay-background;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  overflow-y: scroll;

  &.status-type {
    background-color: transparentize(color("secondary-dark"), 0.2);
  }
}

.modal {
  margin: 0 auto;

  .modal-wrapper {
    padding: 20px;
    border-radius: $modal-overlay-border-radius;
    max-height: 95vh;
    max-width: 90vw;
    overflow: auto;
    background: $modal-background;

    @include breakpoint(medium) {
      max-width: 530px;
    }

    @include breakpoint(large) {
      max-width: 560px;
    }

    @include breakpoint(xlarge) {
      max-width: 600px;
    }

    &.status-type {
      max-width: 210px;
    }

    .modal-header {
      display: flex;
      flex-flow: horizontal wrap;

      .modal-header_close-icon {
        cursor: pointer;
        margin-right: 0;
        margin-left: auto;
        border-radius: 100%;
        border: 0;
        background-color: inherit;
      }
    }
  }
}

.modal-transition-fade {
  transition: opacity ease-in-out 0.2s;
  opacity: 0;

  &.transition-fade-entered,
  &.transition-fade-entering {
    opacity: 1;
  }
  &.transition-fade-exited,
  &.transition-fade-exiting {
    opacity: 0;
  }
}

body.ab-pause-scrolling.ReactModal__Body--open {
  .ab-iam-root.v3 {
    z-index: 1058;
  }
}
