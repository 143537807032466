@import "scss/main";

.faq {
  background-color: color("tertiary-bg");
  padding: 50px 25px;

  @include breakpoint(medium) {
    padding: 60px 50px;
    background-image: url("../../assets/images/illustrations/StarGazingHero/StarGazingHero.svg");
    background-repeat: no-repeat;
    background-position: bottom;
  }
}

.faq__title {
  margin-bottom: 40px;

  @include breakpoint(medium) {
    margin-bottom: 70px;
  }

  &.text-tag {
    @include breakpoint(medium) {
      text-align: center;
    }
  }
}

.faq__list {
  margin: auto;

  @include breakpoint(large) {
    max-width: 1100px;
  }
}
