@import "scss/main";

.list {
  background: $primary-light;
  margin: 0;
  max-width: 100%;
  width: 100%;

  @include breakpoint(medium) {
    max-width: 350px;
  }

  @include breakpoint(large) {
    max-width: 400px;
  }

  .divider {
    margin: 0;
  }
}
