@import "scss/main";

.header-exit {
  cursor: pointer;

  path {
    fill: color("primary-dark");
  }

  &:hover {
    path {
      fill: color("cta");
    }
  }
}
