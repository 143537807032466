@use "scss/main" as *;

.dynamic-form-layout__vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: $max-column-width;
  width: 100%;
  padding: 0 24px 36px;

  @include breakpoint(medium) {
    padding: 0 0 48px;
  }

  .form__input {
    margin-bottom: initial;
  }
}

.dynamic-form-layout__modal {
  .dynamic-form-item--button {
    width: 100%;
    max-width: 350px;
  }
}
