@import "scss/main";

.disclosure {
  .disclosure__wrapper {
    width: 100%;
  }

  .disclosure__name,
  .disclosure__updated {
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;

    @include breakpoint(640px) {
      max-width: 640px;
    }

    @include breakpoint(768px) {
      max-width: 768px;
    }

    @include breakpoint(1024px) {
      max-width: 1024px;
    }

    @include breakpoint(1280px) {
      max-width: 1280px;
    }

    @include breakpoint(1536px) {
      max-width: 1536px;
    }
  }

  .disclosure__name {
    font-size: 36px;
    margin-bottom: 10px;

    @include breakpoint(medium) {
      font-size: 42px;
      margin-bottom: 15px;
    }

    @include breakpoint(large) {
      font-size: 50px;
    }
  }

  .disclosure__updated {
    color: $secondary-text;
    margin-bottom: 15px;

    @include breakpoint(medium) {
      margin-bottom: 35px;
    }
  }

  .disclosure__body {
    border: none;
    width: 100%;

    margin-top: -24px;
  }
}
