@import "scss/main";

.value-props-carousel-card {
  display: flex;
  justify-content: center;
}

.value-props-carousel-card__wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include breakpoint(large) {
    flex-direction: row;
    gap: 60px;
  }
}

.value-props-carousel-card__content {
  max-width: $max-column-width;
  margin-bottom: 20px;
}

.value-props-carousel-card__subtitle {
  color: color("secondary-text");
  margin-top: 8px;

  @include breakpoint(medium) {
    margin-top: 16px;
  }
}

.value-props-carousel-card__image-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;

  @include breakpoint(medium) {
    justify-content: flex-start;
  }
}

.value-props-carousel-card__image {
  @include breakpoint(large) {
    width: $max-column-width;
  }
}
