@import "scss/main";

.honesty-pledge__approval-items {
  .honesty-pledge__approval-item {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 18px;
  }

  .honesty-pledge__check {
    margin-right: 6px;
    min-width: 24px;
    align-self: flex-start;
  }

  .honesty-pledge__approval-item-text {
    max-width: 368px;
  }

  margin-bottom: 36px;
}
