@import "scss/main";

.footer-container {
  display: flex;
  align-items: center;
  background: color("primary-light");
  width: 100%;
  padding: 70px 48px;
  z-index: 80;

  @include breakpoint(medium) {
    padding: 60px 25px;
  }

  @include breakpoint(large) {
    padding: 60px 50px;
  }

  @include breakpoint(xlarge) {
    padding: 60px 100px;
  }

  .footer-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    justify-content: space-between;
    width: 100%;
    gap: 64px;

    @include breakpoint(medium) {
      align-items: stretch;
    }

    .footer__content-wrapper {
      .footer__content--copyright {
        text-align: center;

        @include breakpoint(medium) {
          text-align: left;
        }
      }
    }

    .footer__icons-wrapper {
      @include breakpoint(medium) {
        align-self: flex-start;
      }

      .footer__icons--social-media-wrapper {
        display: flex;
        align-items: center;

        @include breakpoint(medium) {
          margin: 0;
        }

        .footer__social-media-icon {
          cursor: pointer;
          width: 18px;
          margin: 0 11px;

          .svg-wrapper {
            display: flex;
            justify-content: center;
          }
        }
      }
    }

    .footer__logo-wrapper {
      .footer__logo {
        width: 46px;
      }
    }
  }
}

.footer__section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  @include breakpoint(medium) {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.footer__v2 {
  border-top: 1px solid color("secondary-dark");
  @include breakpoint(medium) {
    padding: 32px 24px;
  }

  @include breakpoint(large) {
    padding: 32px 48px;
  }

  .footer-wrapper {
    gap: 20px;
    align-items: center;

    @include breakpoint(medium) {
      flex-direction: row;
    }
  }

  .footer__section {
    flex-direction: column;
  }
}
