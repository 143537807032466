@import "scss/main";

.flow-review__title {
  margin-bottom: 32px;
}

.flow-review__questions,
.flow-review__terms {
  margin-bottom: 36px;
}

.flow-review__question:not(:last-child) {
  margin-bottom: 20px;
}

.flow-review__policy-download {
  color: color("cta");
  cursor: pointer;
  margin-bottom: 24px;

  &.flow-review__policy-download--disabled {
    pointer-events: none;
  }
}
