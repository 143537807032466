@import "scss/main";

$progress-bar-height: 4px;

.progress-bar {
  background-color: color("sand");
  height: $progress-bar-height;
  width: 100%;
}

.progress-bar__fill {
  background-color: color("primary");
  height: $progress-bar-height;
  transition: width ease-in-out 0.3s;
}

.progress-bar__fill--initial-fill {
  min-width: $progress-bar-height * 1.5;
}
