@import "scss/main";

.card-btn {
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;
  width: 136px;
  height: 148px;
  border: 0;
  text-align: left;
  padding-top: 6px;
  padding-left: 15px;
  color: $coal;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: cover;
  transition: background-image 0.2s ease-in;

  @include breakpoint(medium) {
    padding-top: 17px;
    padding-left: 16px;
  }

  &.btn-type--label {
    padding-left: 6px;
  }

  &.card-btn--right {
    padding-left: 24px;

    @include breakpoint(medium) {
      padding-left: 34px;
    }
  }

  &.card-btn--left-centered {
    padding-top: 0;
    justify-content: center;
  }

  &.card-btn--right-centered {
    padding-left: 25px;
    padding-top: 0;
    justify-content: center;
  }

  &.card-btn--left-empty {
    background-image: url("../../assets/icons/BlobBgLeftEmpty/BlobBgLeftEmpty.svg");
  }
  &.card-btn--right-empty {
    background-image: url("../../assets/icons/BlobBgRightEmpty/BlobBgRightEmpty.svg");
  }
  &.card-btn--left-filled {
    background-image: url("../../assets/icons/BlobBgLeftFilled/BlobBgLeftFilled.svg");
  }
  &.card-btn--right-filled {
    background-image: url("../../assets/icons/BlobBgRightFilled/BlobBgRightFilled.svg");
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  &:hover {
    cursor: pointer;
  }

  @include breakpoint(medium) {
    width: 171px;
    height: 186px;
  }

  .card-btn__header-container {
    .card-btn__icon-container {
      .card-btn__icon-wrapper {
        .card-btn__icon {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          max-width: 100%;
          max-height: 100%;
        }
      }

      .card-btn__icon-text {
        margin-left: 10px;
        line-height: 0;
      }
    }

    &.icon-bg {
      .card-btn__icon-wrapper {
        height: 36px;
        width: 36px;
        background-color: $satin;
        border-radius: 50%;
      }
    }
  }

  .card-btn__header-wrapper {
    display: flex;
    align-items: baseline;

    .card-btn__header {
      line-height: normal;
    }
  }

  .card-btn__body-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    max-width: 100px;
    width: 100%;

    @include breakpoint(medium) {
      max-width: 130px;
    }

    &.justify-start {
      justify-content: flex-start;
      max-width: 130px;
    }

    &.justify-center {
      justify-content: center;
      text-align: center;
    }
  }

  .card-btn__text {
    margin-bottom: 8px;
    text-transform: uppercase;
  }
}
