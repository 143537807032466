@import "scss/main";

.container {
  padding-top: calc(40px + #{$header-height-sm});

  @include breakpoint(medium) {
    padding-top: calc(90px + #{$header-height-lg});
  }

  &.mw-400 {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  &.two-col-layout {
    padding-top: calc(24px + #{$header-height-sm});

    @include breakpoint(medium) {
      padding-top: calc(50px + #{$header-height-sm});
    }
  }
  &.has-backnav {
    padding-top: 0;
    .two-col-layout__wrapper {
      padding-top: 0;
    }
  }
}
