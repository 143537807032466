@import "scss/main";

.text-input {
  width: 100%;

  .text-input__wrapper {
    position: relative;
    width: 100%;
    height: 61px;

    @include breakpoint(large) {
      height: 71px;
    }
  }

  .text-input__label-text {
    position: absolute;
    left: 17px;
    top: 50%;
    transform: translateY(-50%);
    transition: top 0.3s;
    color: color("placeholder-text");
    pointer-events: none;
  }

  input {
    width: 100%;
    height: 100%;
    padding: 14px 17px 14px 17px;
    border: none;
    outline: 0;
    background: color("secondary-dark");
    &:focus {
      background: color("tertiary-bg");
    }
  }

  &.text-input--has-label {
    input {
      padding: 35px 17px 14px 17px;
    }
  }

  .text-input__unit {
    color: color("black");
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }

  &.text-input--active {
    .text-input__label-text {
      top: 33%;
      color: color("secondary-text");
    }
  }

  &.text-input--disable-floating-label {
    &.text-input--active {
      .text-input__label-text {
        display: none;
      }
    }

    &.text-input--has-label {
      input {
        padding: 14px 60px 14px 17px;
      }
    }
  }

  &.text-input--error {
    .text-input__label-text {
      color: color("error-dark");
    }
    input {
      color: color("error-dark");
      border: 1px solid color("error-dark");
    }
  }

  &.text-input--disabled {
    opacity: 0.4;
  }

  &.text-input--with-icon-left {
    .text-input__label-text {
      left: 40px;
    }

    input {
      padding-left: 40px;
    }
  }

  &.text-input--with-icon-right {
    cursor: pointer;

    label {
      right: 40px;
    }

    input {
      padding-right: 40px;
    }
  }

  .text-input__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    svg {
      width: 32px;
      height: 32px;
    }
  }

  .text-input__icon--left {
    left: 14px;
    svg {
      width: 16px;
      height: 16px;
    }
  }

  .text-input__icon--right {
    right: 14px;

    &.clear-text-icon {
      z-index: 10;

      &.icon-container {
        width: 18px;
        max-height: 18px;
      }
    }
  }

  .text-input__error-text {
    margin: 5px 0;
    color: color("error-dark");
  }
}
