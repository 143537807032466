@import "scss/main";

.accordion {
  @include breakpoint(medium) {
    padding-bottom: 300px;
  }
}

.accordion__wrapper {
  display: flex;
  flex-direction: column;

  &.two-column {
    @include breakpoint(medium) {
      flex-direction: row;
      justify-content: space-between;
    }

    .accordion__panels {
      flex: 1;

      &:first-of-type {
        @include breakpoint(medium) {
          margin-right: 10px;
        }

        @include breakpoint(large) {
          margin-right: 30px;
        }
      }

      &:last-of-type {
        @include breakpoint(medium) {
          margin-left: 10px;
        }

        @include breakpoint(large) {
          margin-left: 30px;
        }
      }

      .accordion-panel {
        &:last-child {
          @include breakpoint(medium) {
            border-bottom: none;
          }
        }
      }
    }
  }
}

.accordion__panels {
  &:last-of-type {
    .accordion-panel {
      &:last-child {
        @include breakpoint(small) {
          border-bottom: none;
        }
      }
    }
  }
}
