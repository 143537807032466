@use "scss/main" as *;

.home-product-card {
  display: flex;
  flex-direction: column;
  padding: 12px;
  min-height: 150px;
  max-width: $max-column-width;
  width: 100%;
  border-radius: var(--radius-150, 12px);
  background-color: color("background-quartenary");
  box-shadow: box-shadow(large);
  transition: box-shadow 0.2s;
  cursor: pointer;
}

.home-product-card-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.home-product-card-header__title {
  display: flex;
  align-items: center;
  gap: 8px;
  color: color("text-primary");
}

.home-product-card__divider {
  margin: 8px 0;
}

.home-product-card__body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 0 0;

  &:has(> .loader-container) {
    justify-content: center;
    align-items: center;
  }

  > * {
    @include fadeIn();
  }
}

.home-product-card__notice-labels {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.home-product-owned-card {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}

.content-column {
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 50%;
  width: 100%;

  &:first-of-type {
    align-items: flex-start;
  }
  &:last-of-type {
    align-items: flex-end;
  }
}

.home-product-quote-date {
  display: flex;
  gap: 8px;
  color: color("secondary-text");
}

.home-product-wyshes-card .wyshes-card-slider {
  margin-left: -12px;
  padding-left: 12px;
}
