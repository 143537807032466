@import "scss/main";

.ineligible-warning__container {
  .p3-tag__text {
    color: color("smoke");
  }

  .ineligible-warning__iframe {
    border: none;
    margin-bottom: -16px;
    margin-left: 50%;
    transform: translateX(-50%);
    width: 100%; /* fall back for browsers that don't support vw */
    width: calc(100vw - (24px * 2));

    @include breakpoint(medium) {
      max-width: 575px;
    }

    @include breakpoint(large) {
      max-width: none;
      width: calc(970px - (48px * 2));
    }
  }
}
