@import "scss/main";

.centered-layout__container {
  padding-top: calc(24px + #{$header-height-sm});

  @include breakpoint(medium) {
    padding-top: calc(50px + #{$header-height-sm});
  }

  padding-left: 24px;
  padding-right: 24px;

  .centered-layout__title {
    margin-bottom: 35px;
  }

  .centered-layout__content {
    margin: 0 auto;

    @include breakpoint(medium) {
      max-width: 350px;
      padding-left: 0;
      padding-right: 0;
    }

    @include breakpoint(large) {
      max-width: 400px;
    }
  }

  .centered-layout__button-wrapper--back {
    margin: 0 auto;
    max-width: 1200px;

    @include breakpoint(large) {
      padding: 0 50px;
    }
  }

  button {
    width: 100%;
  }
}
