@import "scss/main";

@keyframes trivia-rocketship {
  from {
    transform: translate(-50%, 100%);
  }

  to {
    transform: translate(-50%, -150%);
  }
}

@keyframes trivia-loader-ellipsis {
  to {
    width: 50px;
  }
}

.trivia-intro__container {
  background-color: color("primary-dark");
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  &.trivia-intro__container--in-progress {
    .trivia-intro__blob,
    .trivia-intro__text--in-progress,
    .trivia-intro__loading-indicator--in-progress {
      opacity: 1;
    }
  }

  &.trivia-intro__container--lets-play {
    .trivia-intro__blob,
    .trivia-intro__text--lets-play {
      opacity: 1;
    }
  }
}

.trivia-intro__blob,
.trivia-intro__text,
.trivia-intro__loading-indicator {
  opacity: 0;
  transition: ease-in opacity 0.6s;
}

.trivia-intro__blob {
  position: absolute;
}

.trivia-intro__blob--top {
  left: 0;
  top: 0;
}

.trivia-intro__blob--bottom {
  right: 0;
  top: 502px;

  @media (min-height: 700px) {
    bottom: 0;
    top: auto;
  }
}

.trivia-intro__text,
.trivia-intro__loading-indicator {
  color: color("secondary-dark");
  font-size: 50px;
  font-weight: 700;
  margin: 170px 24px 0;
  max-width: 15rem;
  z-index: 40;
}

.trivia-intro__text {
  &.trivia-intro__text--lets-play {
    position: absolute;
    top: 0;
  }
}

.trivia-intro__loading-indicator {
  user-select: none;
  margin-top: 0;
  animation: trivia-loader-ellipsis steps(4, end) 1500ms infinite;
  overflow: hidden;
  position: relative;
  width: 0;
}

.trivia-intro__rocketship {
  animation: ease-in 1 forwards trivia-rocketship 1s;
  animation-duration: 3s;
  position: absolute;
  bottom: 0;
  left: 50%;
}
